
.settings_error_msg{
    color: #f20000;
    font: normal normal normal 14px/70px PT Sans;
    letter-spacing: 0px;
    display: none;
    height: 0px;
    position: relative;
    top: 40px;
  }

  .settings_inputs {
    height:   40px;
  }

  .settings_inputs {
    border: solid 2px #eee;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    margin-top: 0px;
    padding: 8px 14px 8px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    box-sizing: border-box;
    }
    .settings_inputs:focus {
      background-color: transparent;
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  }
  
.settings_error{
    box-shadow: 0px 3px 6px #FF8282;
    border: 1px solid #f20000;
  }
  
.settings_error_icon{
    position: relative;
    top: -42px;
    left: 28vw;
    width: 25px;
    display: none;
  }
  
.settings_display_error{
    display: inline;
  }
  
/* settings */

.settings{
    display: flex;
    flex-wrap: wrap;
  }
  
.settings_main{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: margin 0.15s ease-in-out;
  /* min-width: 0; */
  flex-grow: 1;
  min-height: calc(100vh);
  margin-left: 260px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  }
  .page_content {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  .settings_main::-webkit-scrollbar{
    display: none;
  }
  
.settings_container{
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  
.settings_main h1{
  font: normal normal bold 24px/60px PT Sans;
  letter-spacing: 0px;
  color: #333333;
  }
  
.settings_form{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: 0 -20px; */
    justify-content: space-between;
    border: solid 1px #eee;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
  }
  
.settings_form_div{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 0;
    width: 44%;
    margin: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 30px;
}

  .container_class{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 80px;
  }
  
.settings_form_div .error_icon{
    top: -40px;
    left: 28vw;
  }
  .flex_image{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
    gap: 15px;
  }

 .flex_image a i{
    vertical-align: top;
    font-size:14px;
  }
  
.settings_form_div .error_msg{
    top: 50px;
  }
  
  
.settings_form .inputs{
    width: 28.5vw !important;
    margin-right: 90px;
    padding-left: 20px;
  }
  
  .action_button{
    display: block;
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }


.settings_submit_btn, .settings_upload_btn{
  /* box-shadow: 0px 15px 20px #00000028; */
  box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
  transition: box-shadow .25s ease,transform .25s ease;
  border-radius: 8px;
  padding: 8px 16px;
  font: bold normal normal 18px/31px PT Sans;
  letter-spacing: 0.96px;
  text-decoration: none;
  transition: 0.3s;
  }
  .settings_submit_btn, .settings_upload_btn{
    margin: 0 10px 10px 10px;
  }
  .settings_submit_btn  {
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    padding: 8px 50px;
  }
  .settings_upload_btn  {
    color: #666;
    background-color: #f2f2f2;
    border: dashed 1px #979696;
  }
  .settings_submit_btn:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
  .settings_submit_btn:hover, .settings_upload_btn:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    transform: translate3d(0,-3px,0);
}
  
.settings_fade{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0.8;
    z-index: 200;
    display: none;
  }
  
.settings_popup{
    position: absolute;
    left: calc(50% - 23vw);
    top: calc(50% - 32vh);
    align-self: center; 
    width: 46vw;
    height: 64vh;
    justify-self: center;
    box-shadow: 0px 0px 50px #44444428;
    border-radius: 30px;
    background-color: #ffffff;
    opacity: 1;
    z-index: 201;
    display: none;
  }
  
.settings_popup_open{
    display: block;
  }
  
.drag_and_drop{
    border: 1px dashed #707070;
    position: relative;
    width: 90%;
    height: 70%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
.drag_and_drop_img{
    position: relative;
    width: 130px;
    top: 0px;
    align-self: center;
  }
  
.uploaded_logo{
  position: absolute;
  width: 100%;
  height: 100%;
  left: calc(50% - 45%);
  z-index: 12;
  display: none;
  /* border: 2px solid red; */
  left: 0;
  right: 0;
  }

.drag_and_drop div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* width: 100%; */
  }
  

  .cross_btn{
    position: absolute;
    width: 25px;
    height: 25px;
    /* border: 2px solid red; */
    /* background-color: red; */
    border-radius: 1000px;
    box-shadow: 0px 3px 15px #7A7A7A23;
    top: 5%;
    right: 3%;
    z-index: 150;
    cursor: pointer;
    display: none;
  }

  .image_uploaded{
    display: block;
  }
  .image_uploaded_new{
    display: block;
    width: 40%;
    height: auto;
    margin: 0 auto;
  }
  
.drag_and_drop h5{
    position: relative;
    top: -40px;
    font: normal normal normal 21px/27px PT Sans;
    letter-spacing: 0px;
    color: #D8D8D8;
    align-self: center;
  }
  
.drag_and_drop p{
    font: normal normal normal 18px/27px PT Sans;
    letter-spacing: 0px;
    color: #808285;
    position: relative;
    top: 0px;
    align-self: center;
  }
  .drag_and_drop p{
    margin-bottom: 20px;
  }
  
/* .settings_upload_btn{
    color: #FFFFFF;
    background-color: #F7941D;
    border: none;
    width: 200px;
    height: 45px;
    box-shadow: 0px 15px 20px #00000028;
    border-radius: 21px;
    font: normal normal normal 24px/31px PT Sans;
    letter-spacing: 0.96px;
    align-self: center;
    position: relative;
    top: 50px;
  } */
  
.popup_btn_right{
    left: 0px;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  
.popup_btn_left{
    background-color: #777777;
    border: solid 1px #777777;
    padding-left: 50px;
    padding-right: 50px;
    
  }
  .popup_btn_left:hover{
    background-color: #555555;
    border: solid 1px #555555;
  }
.popup_btn_container{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 10px;
  }  

  /* @media screen and (max-width: 1872px){

    .settings_error_icon{
      left: 25vw;
    }

    .settings_main{
      padding-right: 0;
    }

    .settings_inputs{
      width: 26vw !important;
    }
  } */

  /* @media screen and (max-width: 1254px){
    .settings_form{
      display: flex;
      flex-direction: column;
    }

    .settings_form_div{
      width: 40%;
      align-self: center;
      position: relative;
      left: -0px;
    }
    .settings_main h1{
      width: 90%;
      text-align: center;
      margin-right: 0;
      align-self: center;
    }
  } */
  @media screen and (max-width:1100px){
    .settings_form{
      padding:10px  0px;
      /* width: 42%; */
    }
    .settings_form_div{
      width: 42%; 
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width:1023px){
  
    .settings_form_div{
      display: block;
      width: 100%;
    }
    .page_content {
      margin: 14px;
      padding: 14px;
  }
  /* .settings_form, .settings_form_div{
    margin: 10px 0;
  } */
}