/* dashboard */

  .dashboard{
    display: flex;
  }
  .dashboard_main{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    /* min-width: 0; */
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }
  .page_content {
    /* height: 100vh; */  
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  .flex_image{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-top: 30px;
    gap: 15px;
  }
  .flex_image a i{
    vertical-align: top;
    font-size:14px;
  }
  .dashboard_main h1{
    font-size: 24px;
    font-family: 'nevis';
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0px;
    color: #333333;
  }
  @media only screen and (max-width: 767px) {
    .dashboard_main h1{
      font-size: 18px;
    }
  }
  .dashboard_header{
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
  }
  @media only screen and (max-width: 767px) {
    .dashboard_header{
      margin-top: 0px;
    }
    #simple-tabpanel-0
    .MuiBox-root-10 {
        padding: 10px 0;
    }
  }

/* welcome section csss */

.welcome_sec{
  background-color: #E1F5FF;
  display: block;
  border-radius: 10px;
  padding: 30px 30px 30px;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%); */
}
.welcome_row{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.welcome_col_left h1{
  margin: 0;
  font: normal normal normal 24px/36px PT Sans;
}
.welcome_col_left h1 span{
  font: bold normal normal 24px/24px PT Sans;
  display: inline-block;
  color: #12467B;
}
.welcome_col_left p{
  color: #333333;
  font: normal normal normal 16px/20px PT Sans;
  margin-top: 6px;
}
.welcome_col_left p span{
  display: inline-block;
  font: bold normal normal 16px/24px PT Sans;
  /* border-bottom: solid 2px #333333; */
  text-decoration: underline;
}
.welcome_col_left, .welcome_col_right{
  display: block;
  position: relative;
}
.welcome_col_right img{
  margin-top: -60px;
  padding-right: 30px;
}

.statistics_sec{
  display: block;
  box-sizing: border-box;
  margin-top: 30px;
}
.statistics_row{
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
}
.statistics_card{
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 20px;
  padding:20px 24px;
  display: block;
  width: 100%;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  margin-bottom: 1.5rem;
  background-color: #fff;
  border: 1px solid #edf2f9;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 6%); */
  /* -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%); */
  height: 100%!important;
}

.card_row{
  display: flex;
  justify-content: space-between;
}
.card_col_left p, .card_col_right p{
  font: normal normal normal 14px/32px PT Sans;
  color: #808285;
  
}
.card_col_left h4, .card_col_right h4{
  font: bold normal normal 28px/32px PT Sans;
  color: #222;
}
.card_col_right .ass_icon{
  height: 150px;
  width: 150px;
  position: absolute;
  right: -8px;
  bottom: -40px;
  opacity: 0.2;
}
  .showing_results{
    display: flex;
    color: #808285;
  }
  .showing_results h5{
    font: normal normal normal 18px/60px PT Sans;
    letter-spacing: 0px;
    display: inline-block;
  }
  .showing_results select{
    width: 60px;
    height: 35px;
    border: 1px solid #bababb;
    border-radius: 2px;
    font-size: 100%;
    color: #808285;
    padding: 6px;
    margin-left: 15px;
    align-self: center;
    border-radius: 4px;
  }
  
  #new_btn_container{
    position: relative;
  }
  
  #new_btn{
    background-color: #12467B;
    border: none;
    /* height: 45px; */
    display: flex;
    align-self: center;
    border-radius: 6px;
    margin-right: 5px;
    padding: 10px 14px;
    font: bold normal normal 16px/14px PT Sans;
    letter-spacing: 0.8px;
    color: #ffffff;
    box-shadow: 0px 15px 20px #00000028;
    transition: all 1s;
    -webkit-transition: all 1s; 
  }
  #new_btn:hover{
    background-color: #042452;
    box-shadow: none;
  }
  @media only screen and (max-width: 767px) {
    #new_btn {
      padding:6px 10px;
      font: bold normal normal 14px/14px PT Sans;
    }
    #new_btn img {
      height: 14px;
    }
  }

  .video-icon {
    width: 48px;
    height: 48px;
}
@media only screen and (max-width: 600px) {
  .video-icon {
    width: 30px;
  }
}
  #new_btn img{
    margin-right: 10px;
    height: 16px;
  }

  .drop_down{
    position: absolute;
    top: 44px;
    right: 0;
    /* height: 125px; */
    min-width: 180px;
    box-shadow: 0 1.5rem 4rem rgb(22 28 45 / 15%);
    border-radius: 10px;
    padding: 10px 15px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    margin: 10px;
  }
  
  .show_drop_down{
    display: flex;
  }
  
  .drop_down_btn{
    font: normal normal normal 16px/31px PT Sans;
    letter-spacing: 0px;
    color: #444;
    font-weight: 600;
    padding:4px 15px;
    text-decoration: none;
    /* background-color: #f2f2f2; */
    margin-bottom: 10px;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  }
  .drop_down_btn:hover{
    background-color: #f5f5f5;
  }
  .drop_down_btn:last-child{
    margin-bottom: 0;
  }
  .drop_down_btn:hover{
    color: #12467B;
  }
 
  .table_head{
    margin-top: 0;
    width: 100%;
    background-color: #f9fbfd;
    border-radius: 6px;
    height: 60px;
    display: flex;
    color: #333333;
    /* font: normal normal normal 16px/60px PT Sans; */
    letter-spacing: 0px;
    align-items: center;
    border: 1px solid #edf2f9;
  }
  
  .table_head span img{
    margin-left: 10px;
  }
  
  .table_head_name{
    width: 20%;
  }
  @media only screen and (max-width: 767px) {
    .table_head_name{
      width: auto;
      padding: 12px;
    }
  }
  
  .table_head_date{
    margin-right: 6vw;
  }
  
  .table_head_company{
    margin-right: 4vw;
  }
  
  .table_head_notes{
    margin-right: 5vw;
  }
  
  /* table row */
  .table_row{
    margin-top: 15px;
    width: 100%;
    /* background-color: #F8F8F8; */
    /* border-radius: 15px; */
    height: 60px;
    /* line-height: 60px; */
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: #4d4d4d;
    font: normal normal normal 16px PT Sans;
    letter-spacing: 0px;
    border: 1px solid #edf2f9;
    box-shadow: 0px 2px 6px 0px rgba(82, 63, 105, 0.06);
    -webkit-box-shadow: 0px 2px 6px 0px rgba(82, 63, 105, 0.06);
  }
  
  .table_row:hover{
    background-color: #f9fbfd;
  }
 
  .table_row_name{
    margin-left: 4vw;
    margin-right: 20vw;
  }
  
  .table_row_date{
    margin-right: 5vw;
  }
  
  .table_row_company{
    margin-right: 5vw;
  }
  
  .table_row_notes{
    /* margin-right: 6vw; */
    /* display: flex;
    align-items: center; */
    /* width: 31px; */
    position: relative;
    --c : none;
  }
  
  .table_row_notes:hover{
    --c: block;
  }
  
  .table_row_note{
    position: absolute;
    right: 0px;
    top: 12px;
    width: 229px;
    z-index: 1;
    height: 64px;
    text-align: left;
    font: normal normal normal 14px/22px PT Sans;
    letter-spacing: 0px;
    color: #808285;
    box-shadow: 0px 0px 50px #44444428;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    display: var(--c);
  }
  
  .table_row_status{
    color: #53B7E8;
  }
  
  .complete{
    color: #96BC33;
  }
  
  /* dashboard footer */
  
  .dashboard_footer{
    margin-top: 40px;
    height: 60px;
    display: flex;
    margin-bottom: 40px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
  
  .progress_bar{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    left: 0;
    width: 400px;
    align-self: center;
  }
  
  .progress{
    background-color: #1957ac;
    width: 50%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
  .page_btn_container{
    /* position: relative; */
    /* width: 170px; */
    /* height: 60px; */
    /* right: -17vw; */
    border-radius: 8px;
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    
    /* font-size: 400%; */
    /* justify-content: center; */
  }
  
  .page_btn_container h1{
    font-size: 40%;
  }

  

  /* @media screen and (max-width: 1750px) {
    .table_row{
      font: normal normal normal 18px/55px PT Sans;
      letter-spacing: 0px;
      height: 40px;
    }

    .table_head{
      font: normal normal normal 18px/55px PT Sans;
    letter-spacing: 0px;
    height: 40px;
    
    }

    .table_head img{
      width: 10px;
    }

    .table_row_notes img{
      width: 18px;
    }

    .table_head_notes{
      margin-right: 6vw;
    }



    .table_head_date{
      margin-right: 5vw;
    }

    .table_head_company{
      margin-right: 6vw;
    }

    .dashboard_footer{
      width: 94%;
    } 
  } */

  /* @media screen and (max-width: 1570px) {

    .dashboard_main h1{
      font: normal normal bold 25px/40px PT Sans;
    }

    .showing_results h5{
      font: normal normal normal 20px/32px PT Sans;
    }

    .showing_results select{
      width: 40px;
      height: 30px;
      font-size: 80%;
      padding-left: 0px;
      align-self: flex-start;
    }

    #new_btn_container{
      width: 100px;
      height: 35px;
      position: relative;
    }
    
    #new_btn{
      color: #FFFFFF;
      background-color: #F7941D;
      border: none;
      width: 100px;
      height: 35px;
      box-shadow: 0px 15px 20px #00000028;
      border-radius: 25px;
      font: normal normal normal 18px/25px PT Sans;
      letter-spacing: 0.96px;
    }
    
    #new_btn img{
      margin-right: 15px;
      width: 15px;
    }

    .drop_down{
      position: absolute;
      top: 40px;
      left: -65px;
      height: 95px;
      width: 180px;
      box-shadow: 0px 10px 30px #36363628;
      border-radius: 26px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: none;
    }

    .show_drop_down{
      display: flex;
    }
    
    .drop_down_btn{
      font: normal normal normal 16px/25px PT Sans;
      letter-spacing: 0.96px;
      color: #2D0505;
      font-weight: bold;
      padding: 5px;
      text-decoration: none;
    }
    

    .table_row{
      font: normal normal normal 13px/42px PT Sans;
      letter-spacing: 0px;
    }

    .table_head{
      font: normal normal normal 13px/42px PT Sans;
    letter-spacing: 0px;
    }

    .dashboard_footer{
      width: 80%;
    }

   

    .table_row_note{
      width: 130px;
      height: 50px;
      font: normal normal normal 10px/15px PT Sans;
    }

    .table_row_company{
      margin-right: 7vw;
    }

    .table_row_name{
      margin-left: 2vw;
    }

    .dashboard_footer{
      margin-top: 100px;
      height: 60px;
      display: flex;
      justify-content: center;
      margin-bottom: 120px;
      width: 95%;
    }
    
    .progress_bar{
      position: relative;
      background-color: #F0F0F0;
      box-shadow: 0px 5px 10px #44444429;
      border-radius: 10px;
      height: 10px;
      left: 5vw;
      width: 350px;
      align-self: center;
      justify-self: center;
    }
    
    .progress{
      background-color: #F7941D;
      width: 20%;
      height: 10px;
      box-shadow: 0px 5px 10px #44444428;
      border-radius: 10px;
    }
    
    .page_btn_container{
      position: relative;
      width: 90px;
      height: 40px;
      right: -20vw;
      top: 10px;
      border-radius: 8px;
      background-color: #F1F1F1;
      display: flex;
      font-size: 250%;
      justify-content: center;
    }
    
    .page_btn_container h1{
      font-size: 30%;
    }
  
  } 

  @media screen and (max-width: 1485px) {
    .table_row{
      font: normal normal normal 10px/30px PT Sans;
      letter-spacing: 0px;
    }

    .table_head{
      font: normal normal normal 10px/30px PT Sans;
    letter-spacing: 0px;
    }

    .dashboard_footer{
      width: 80%;
    }
    
    .table_row_company{ 
       margin-right: 7vw;
      text-align: center;
      justify-content: center;
       align-items: center;
      line-height: 60px;
    }
  } */
@media screen and (max-width:992px) {
.welcome_col_right img {
  margin-top: -20px;
  padding-right: 0;
  max-height: 80px;
}
.welcome_col_left h1, .welcome_col_left h1 span {
  /* font: normal normal normal 24px/36px PT Sans; */
  font-size: 24px;
  font-family: 'nevis';
  font-weight: bold;
  font-style: normal;
}
.welcome_sec {
    padding: 10px 10px 0;
}
.welcome_col_left p span{
  font-size: 14px;
}
.page_content{
  margin: 14px;
  padding: 14px;
}
.card_col_right .ass_icon {
  height: 80px;
  width: 80px;
  right: -8px;
  bottom: -10px;
}
.statistics_card{
  padding: 10px;
}
.table_head_name {
  font-size: 14px;
}
}
@media screen and (max-width:767px) {

  .dashboard_footer, .card_row, .statistics_row, .statistics_card{
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  .page_btn_container{
    justify-content: center;
    margin-top: 20px;
  }
  .welcome_col_right img {
    margin-top: 0;
    padding-right: 0;
    max-height: 60px;
}
.welcome_col_left p {
    font: normal normal normal 18px/16px PT Sans;
    font-size: 13px;
}
.welcome_col_left h1, .welcome_col_left h1 span {
    font: normal normal normal 18px/36px PT Sans;
}

}