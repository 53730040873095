@import url(https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.loader_1 {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #1954a4;
  border-bottom: 16px solid #1954a4;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: 0 auto;
  margin-bottom: 30px;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
.not-found-page{
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  font-size: 90px;
}
/* @import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap"); */
@font-face {
  font-family: 'nevis';
  src: url(/static/media/nevis-Bold.38c51609.woff2) format('woff2'),
      url(/static/media/nevis-Bold.fcf80e41.woff) format('woff'),
      url(/static/media/nevis-Bold.6aae5c61.ttf) format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'PT Sans', sans-serif;
}
a, a:hover, a:focus{
  color: #12467B;
  text-decoration: none;
}

.login {
  display: flex;
  /* height: 100%; */
  height: 100vh;
}
.text-danger{
  color: #f20000;
}

.left {
  position: relative;
  height: 100vh;
  /* min-height: 710px; */
  overflow: hidden;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#1d457b, #1957ac);
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 9998;
}
.login_logo {
  position: absolute;
  top: 10%;
  /* left: 2%; */
  width: 100%;
  z-index: 100;
  /* right: 2%; */
  margin: 0 auto;
  max-width: 400px;
}

.add-btn-pos{
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    float: right;
    position: relative;
    top: 42px;
    z-index: 99;
}
@media only screen and (max-width: 600px) {
  .add-btn-pos {
    top: -10px;
  }
}
.mr-1{
  margin-right: 5px;
}
.mr-3{
  margin-right: 20px;
}
.upper_circle {
  position: absolute;
  top: -40%;
  width: 40vw;
  height: 40vw;
  border-radius: 10000000000px;
  background-image: linear-gradient(#1957ac, #1d457b);
}

.welcome-text h2 {
  color: #ffffff;
  font-size: 250%;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
}
.custom-typehead > div:first-child input {
  width: 1000%;
  border: solid 2px #eee;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  margin-top: 0px;
  padding: 8px 14px 8px 20px;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: #fff;
  transition: all 1s;
  -webkit-transition: all 1s;
  box-sizing: border-box;
}
.custom-typehead .rbt-menu{
  background:#fff;
  border: 1px solid #ccc;
}
.custom-typehead .rbt-menu a {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  font-size: 15px;
}
.remove-file{
  margin-left: 5px;
  background: transparent;
  border:1px solid #000;
  height:20px;
  width:20px;
  border-radius: 50px;
}
.lower_circle {
  position: absolute;
  bottom: -50%;
  width: 35vw;
  height: 35vw;
  border-radius: 10000000000px;
  background-image: linear-gradient(#1957ac, #1d457b);
  overflow: hidden;
}

#login_right {
  width: 60%;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  width: 28vw;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 40px 40px;
  margin-top: 30px;
}

.d-flex {
  display: flex;
}

button {
  cursor: pointer;
}

#login_heading {
  display: flex;
  justify-content: space-between;
  width: 28vw;
  /* margin-left: 180px; */
  /* margin-top: 23%; */
  margin-bottom: 15px;
}

#login_heading h4 {
  font-size: 200%;
  font-weight: bold;
  color: #333333;
  align-self: center;
  font-family: 'PT Sans', sans-serif;
}

#login_heading img,
#register_heading img {
  width: 8%;
  cursor: pointer;
}

.login_container {
  background-color: #fff;
  /* border: 2px solid red; */
  color: black;
  margin-top: 0px;
  width: 28vw;
  /* margin-left: 180px; */
}

form {
  display: flex;
  flex-direction: column;
}

label {
  align-self: start;
  font-size: 18px;
  color: #575757;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.inputs {
  /* box-shadow: 0px 3px 6px #00000029; */
  border: solid 2px #eee;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  margin-top: 0px;
  padding: 8px 8px 8px 74px;
  font-size: 18px;
  letter-spacing: 1px;
  background-color: #fff;
  transition: all 1s;
  -webkit-transition: all 1s;
  box-sizing: border-box;
}

.inputs:focus {
  /* border: 1px solid #1957AC; */
  background-color: transparent;
  /* box-shadow: 0px 0px 4px #00000028; */
  box-shadow: 0 0 10px rgb(0 0 0 / 6%);
}
.assessment-download, .report-download{
  padding: 5px;
  margin-right: 10px;
  margin-top: 20px;
  background: transparent;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
}
.mt-3{
  margin-top: 10px;
}
.input_icons {
  position: relative;
  top: -42px;
  left: 24px;
  height: 24px;
  width: 24px;
}

.indicator {
  display: none !important;
}

.is-active .indicator {
  display: block !important;
}

#footer {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  width: 28vw;
  font-size: 100%;
}

#radio_container {
  padding: 0;
  margin: 0;
}

#radio_btn {
  width: 10px;
  height: 10px;
  border: 1px solid #808285;
  border-radius: 5px;
  padding: 8px;
  -webkit-appearance: none;
  position: relative;
  top: 4px;
}

#radio_container span {
  margin-left: 10px;
  font-size: 90%;
}
.no-record{
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
input[type="radio"]:checked {
  background: url(/static/media/tick.295cb6b4.png) no-repeat center center;
  background-size: 15px 15px;
}
.custom_date_picker {
  width: 100%;
  padding-left: 15px;
}
.custom_date_picker label {
  display: block;
}
.custom_date_picker .custom-picker {
  width: 100%;
  height: 60px;
  border-radius: 5px;
}
.custom_date_picker .custom-picker .react-date-picker__wrapper {
  border-color: #eeeeee;
  border-radius: 10px;
}
.custom_date_picker .custom-picker .react-date-picker__wrapper .react-date-picker__inputGroup {
  padding-left: 10px;
}
.react-date-picker__inputGroup {
  padding: 10px 6px;
  border: 1px solid #eee;
}
.worksheet-exit-button{
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer a {
  color: #1957ac;
  /* text-decoration: none; */
  font: normal normal normal 16px/24px PT Sans;
  font-family: sans-serif;
  transition: all 1s;
  -webkit-transition: all 3s;
}

#footer a:hover {
  text-decoration: underline;
  color: #1d457b;
}

#login_submit_btn {
  background-color: #96bc33;
  border: none;
  width: 100%;
  align-self: center;
  box-shadow: 0px 15px 20px #00000028;
  border-radius: 6px;
  font: bold normal normal 20px/26px PT Sans;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 0px;
  transition: all 1s;
  -webkit-transition: all 1s;
  padding: 15px 0;
}

#login_submit_btn:hover {
  background-color: #59721b;
  box-shadow: none;
}

form p {
  font: normal normal normal 18px/24px PT Sans;
  letter-spacing: 0px;
  color: #808285;
  align-self: center;
  margin-top: 20px;
  /* padding-bottom: 120px; */
}

form p a {
  color: #1957ac;
  text-decoration: none;
  font: normal normal normal 18px/24px PT Sans;
}
.login-link,
.register-link {
  width: 98%;
  margin: auto;
  padding: 20px 30px;
  box-sizing: border-box;
}
.login-link p,
.register-link p {
  font: normal normal normal 18px/24px PT Sans;
  letter-spacing: 0px;
  color: #808285;
  margin-top: 0;
  text-align: right;
}
.login-link p a,
.register-link p a {
  color: #1957ac;
  text-decoration: none;
  font: normal normal normal 18px/24px PT Sans;
}

/* register page */

.register {
  display: flex;
  height: 100vh;
}
#forgot_right,
#register_right {
  width: 60%;
  min-height: 100%;
  background-color: #f5f5f5;
  margin: 0 auto;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 40px;
}
#forgot_right {
  display: flex;
}
.register-box {

  width: 28vw;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 40px;
}
.react-tel-input .form-control {
  font-size: 18px !important;
  width: 100% !important;
}
#register_heading {
  display: flex;
  justify-content: space-between;
  width: 28vw;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 30px;
}

#register_heading h4 {
  font-size: 200%;
  font-weight: bold;
  color: #333333;
  align-self: center;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}
.worksheet_popup_width select{
  width: 100%;
}
.worksheet_popup_width .custom_date_picker{
  padding-left:0
}
#register_submit_btn,
#reset_submit_btn {
  background-color: #96bc33;
  border: none;
  /* height: 45px; */
  width: 100%;
  align-self: center;
  box-shadow: 0px 15px 20px #00000028;
  border-radius: 6px;
  font: bold normal normal 20px/26px PT Sans;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 0px;
  transition: all 1s;
  -webkit-transition: all 1s;
  padding: 15px 0;
}

#register_submit_btn:hover,
#reset_submit_btn:hover {
  background-color: #59721b;
  box-shadow: none;
}

.register_form label {
  margin-top: 2px;
}

/* form validation */

.error_msg {
  color: #f20000;
  font: normal normal normal 14px/70px PT Sans;
  letter-spacing: 0px;
  display: none;
  height: 0px;
  position: relative;
  top: 40px;
}

.error {
  box-shadow: 0px 0px 2px #ff8282;
  border: 1px solid #f20000;
}

.error_icon {
  position: relative;
  top: -64px;
  left: 101%;
  width: 20px;
  /* display: none; */
}

.page-iniate {
  background: transparent;
  border: 0;
}
.page-iniate{
    font-size: 40px;
    line-height: 20px;
}
.Toastify__toast.Toastify__toast--error,.toastBody  {
  z-index: 100000;
}
.pagenum span{
    font-size: 20px;
    line-height: 20px;
    display: inline-block;
}

.display_error {
  display: inline;
}
/* side panel */

.side_panel {
  background-image: linear-gradient(#1d457b, #1957ac);
  /* display: flex;
  justify-content: center; */
  overflow: hidden;
  width: 260px;
  height: 100vh;
  z-index: 1038;
}
.side_panel{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.user-profile, .logo{
    display: block;
    width: 100%;
    text-align: center;
}
.logo_sidepanel {
    /* position: absolute; */
    width: 100%;
    max-width:80%;
    display: inline-block;
    z-index: 100;
    margin: 30px auto;
}

.upper_circle_side_panel {
    position: absolute;
    top: 0;
    width: 20vw;
    height: 20vw;
    border-radius: 10000000000px;
    background-image: linear-gradient(#1d457b, #1957ac);
    -webkit-transform: rotate(-150deg);
            transform: rotate(-150deg);
    z-index: -9;
}
.user_avatar {
    /* position: absolute; */
    width: 80px;
    height: 80px;
    object-fit: contain;
    /* top: 16%; */
    z-index: 101;
    border-radius: 100%;
    border: 1px solid #000000;
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
}

.peach-theme .user_avatar, .gray-theme .user_avatar {
  border: 1px solid #000000;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
  display: block;
}
.video-icon{
    width: 48px;
    height: 48px;
  }
.user_name {
    font: normal normal bold 18px/31px PT Sans;
    letter-spacing: 0px;
    color: #ffffff;
    text-shadow: 0px 5px 6px #00000029;
    font-size: 100%;
    margin-top: 10px;
}
.peach-theme .user_name, .gray-theme .user_name{
  color: #000;
}
.side_panel_btn_container {
    margin-top: 50px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
} 

.side_panel_btn {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-bottom: 30px;
    text-decoration: none;
    padding: 8px 20px;
    margin: 20px 10px;
    border-radius: 6px;
    box-sizing: border-box;
    box-shadow: 0px 15px 50px 0px rgb(82 63 105 / 15%);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    background-color: #404244;
}
.blue-theme .side_panel_btn{
  background-color: #0c355e;
}
.side_panel_btn:hover{
    background-color: #042452;
}

.button{
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .375rem;
    color: #161c2d;
    cursor: pointer;
    display: inline-block;
    font-size: 1.0625rem;
    font-weight: 600;
    line-height: 1.6;
    padding: .8125rem 1.25rem;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -ms-user-select: none;
        user-select: none;
    vertical-align: middle;
}
.btn-green{
    background-color: #96BC33;
    border:solid 1px #96BC33;
}
.btn-green:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
}
.side_panel.green-theme+div .worksheet_form_header_h3{
  color:#60830a
}
.side_panel.blue-theme+div .worksheet_form_header_h3{
  color:#1957ac
}
.side_panel.peach-theme+div .worksheet_form_header_h3{
  color:#e27059
}
.side_panel.gray-theme+div .worksheet_form_header_h3{
  color:#434343
}
.lift{
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
}
.lift:focus, .lift:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    -webkit-transform: translate3d(0,-3px,0);
            transform: translate3d(0,-3px,0);
}

.indicator {
    /* border: 2px solid green; */
    width: 6px;
    height: 50px;
    border-radius: 2px;
    background-color: #96BC33;
    visibility: hidden;
    position: absolute;
    z-index: 1;
    left: 0;
}

.active_tab {
  visibility: visible;
}

.side_panel_btn_icon {
    position: relative;
    width: 22px;
    height: 20px;
    display: inline-block;
}

.side_panel_btn_text {
  font: normal normal normal 18px/31px PT Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 5px 6px #00000029;
  margin-left: 10px;
}

.logout_btn {
    position: absolute;
    bottom: 0;
    display: flex;
    z-index: 105;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    text-decoration: none;
    background-color: #12467B;
    padding: 10px 0;
}

.lower_circle_side_panel {
  position: absolute;
  bottom: -50px;
  left: 0%;
  width: 24vw;
  height: 25vw;
  border-radius: 10000000000px;
  /* background-image: linear-gradient(#1957ac, #1d457b); */
  /* background-image: linear-gradient(#1957ac, #1d457b); */


  overflow: hidden;
  z-index: -9;
}
/* reset password page */

#reset_heading {
  display: flex;
  justify-content: space-between;
  /* margin-top: 15%; */
  margin-top: 0;
  margin-bottom: 20px;
}

#reset_heading h4 {
  font-size: 200%;
  font-weight: bold;
  color: #333333;
  align-self: center;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}
.otp-input-group {
  width: 100%;
  position: relative;
}

.otp-input-group .inputs {
  padding: 8px 100px 8px 74px;
}
.send_otp {
  position: absolute;
  top: 0px;
  right: 0;
  height: 60px;
  border-radius: 0px 10px 10px 0px;
  padding: 0 14px;
  background-color: #53b7e8;
  color: #ffffff;
  border: none;
  font: 600 normal normal 18px/40px PT Sans;
  letter-spacing: 0px;
}

/* #quickTable td,
#quickTable th {
  padding: 10px;
  border: solid 1px #e8e8e8;
}
#quickTable{
  width: 100%;
  border-collapse: collapse;
  
}
#quickTable th {
    background-color: #f2f2f2;
}
.ReactModalPortal button{
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: solid 1px #f5f5f5;
  font-weight: 600;
}
.ReactModal__Overlay{
  z-index: 9999;
} */

/* @media screen and (max-width: 1570px) {
  .side_panel {
    width: 18%;
  }
  .logo_sidepanel {
    width: 55%;
  }
  .user_avatar {
    width: 60px;
    height: 60px;
  }
  .user_name {
    font: normal normal bold 18px/24px PT Sans;
  }
  .indicator {
    height: 30px;
    width: 5px;
  }
  .side_panel_btn_icon {
    width: 35px;
    top: 4px;
    margin-left: 20px;
  }
  .side_panel_btn_text {
    font: normal normal normal 20px/26px PT Sans;
  }
} */



.analyeze_form_part{
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.analyeze_form_part .analyzing_report-from-group {
  min-width: 31.2%;
  border: 1px solid #eee;
  padding: 10px 15px;
  margin-left: -1px;
  margin-top: -1px;
  display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    display: -webkit-flex !important;
    -webkit-align-items: center !important;
    -webkit-justify-content: space-between;
}
.analyeze_form_part .analyzing_report-from-group.anlyze_last_report {
  width: 64.4%;
  margin-left: 1px;
  border-left: none;
}
.analyeze_form_part .analyzing_report-from-group > label {
  margin: 0;
  align-self: initial;
  -webkit-align-self: initial;
}
.analyeze_form_part .analyzing_report-from-group > div > span {
  margin-left: 5px;
}
.analyeze_form_part .analyzing_report-from-group > div > input#otherDocument {
  margin-left: 20px;
}
#analyzeTextarea > div textarea,
#analyzeTextarea > div label,
#analyzeTextarea > div {
  width: 100%;
}
div#analyzeFooter {
  margin-bottom: 0;
  margin-top: 30px;
}
.analyze_run_rate {
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}
.analyze_run_rate > div {
  margin: 0;
  width: 33.33%;
}
.analyze_run_rate > div > div {
  width: 100%;
  padding-right: 20px;
}
.analyze_run_rate > div:last-child > div{
  padding-right: 0px;
}
.analyze_run_rate > div > div label {
  width: 100%;
  line-height: 26px;
}
.analyze_run_rate > div > div input,
.analyze_run_rate > div > div select {
  width: 100%;
}
.worksheet_popup_data.custom_modal_design {
  width: 90%;
  margin: 0;
  padding: 5%;
}
.popup_btn_container.custom_modal_cta {
  width: 90%;
  padding: 5%;
  margin: 0;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
}
.custom_modal_cta button {
  margin: 0 10px;
}
.custom_modal_design > div > select, .custom_modal_design > div > input {
  width: 100%;
  padding-left: 10px;
}
.custom_modal_design > div .worksheet_popup_input {
  width:calc(100% - 10px);
  padding-left: 10px;
}
.custom_price_table {
  width: 100%;
  margin: 0;
  /* border: 1px solid #d2d2d2; */
  /* padding: 0px; */
  border-collapse: collapse;
  padding: 20px 10px;
  
}
.custom_price_table tbody th, .custom_price_table tbody td {
  /* border-top: 1px solid #ccc; */
  border-spacing: 0px;
}
.table_btn {
  display: flex;
  justify-content: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  padding: 20px 10px;
}
.analysis-table-report{
  background-color: #12467B;
  padding: 20px 10px;
}
.ReactModal__Content{
  padding: 0!important;
  border: 0!important;
  border-radius: 10px!important;
  box-shadow: 0 1.5rem 4rem rgba(22,28,45,.1)!important;
  min-width: 800px!important;
}

.custom_price_table tbody tr:nth-child(even) {background: #f9f9f9}
.custom_price_table tbody tr:nth-child(odd) {background: #FFFFFF}

/* .analysis-table tbody tr th, */
.analysis-table thead tr th{
  background-color: #f9f9f9;
}
.analysis-table thead tr th,
.analysis-table tbody tr th {
  text-align: left;
  
}
.analysis-table thead tr th,
.analysis-table tbody tr td,
.analysis-table tbody tr th{
  padding: 16px 20px;
  font-size: 14px;
  border-top: 1px solid #ddd;
}

.table_btn a {
  display: block;
}
.table_btn a button, .table_btn a.report-download,.table_btn a button, .table_btn .report-download {
  padding: 10px 15px;
  font-size: 18px;
  background: #96BC33;
  border: none;
  color: #fff;
  text-align: center;
}
.ReactModal__Overlay .ReactModal__Content > button {
  top: 10px !important;
  right: 10px !important;
  padding: 6px !important;
  line-height: 8px;
  font-weight: 800;
  background-color: #fff !important;
    border: solid 2px #ddd!important;
    width: 24px;
    height: 24px;
    border-radius: 100px;
   
}
.note_image_icon:hover + div,.note_image_icon + div:hover{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}
.undo_btn{
  display: block;
  margin-top: 10px;
  color: #FFFFFF;
  background-color: #f20 !important;
  border: none;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  padding: 8px 16px;
  /* height: 45px; */
  box-shadow: 0px 15px 20px #00000028;
  box-shadow: 0 0.5rem 1.5rem rgb(22 28 45 / 10%);
  transition: box-shadow .25s ease,-webkit-transform .25s ease;
  transition: box-shadow .25s ease,transform .25s ease;
  transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
  border-radius: 8px;
  font: bold normal normal 18px/24px PT Sans;
  /* letter-spacing: 0.46px; */
}

#simple-menu{
  z-index: 9999 !important;
}
.mt-20{
  margin-top: 20px !important;
}
.ml-10{
  margin-left: 10px !important;
}
.p_m_button{
  display: flex;
  height: 35px;
  justify-content: center;
}
.p_m_button button{
  width: 30px;
  font-size: 22px;
  border-radius: 0px;
  margin-right: -1px;
  font-weight: bold;
}
.custom_exp .name,.custom_exp .value,.custom_exp .actual,.custom_exp .q1,.custom_exp .q2,.custom_exp .q3,.custom_exp .q4,.custom_exp .q5,.custom_exp .q6,.aq1,.aq2,.aq3,.aq4,.aq5,.aq6{
  border-radius: 6px;
  height: 13px;
  padding: 10px;
}
.custom_exp .value,.custom_exp .actual,.custom_exp .q1,.custom_exp .q2,.custom_exp .q3,.custom_exp .q4,.custom_exp .q5,.custom_exp .q6,.aq1,.aq2,.aq3,.aq4,.aq5,.aq6{
  width: 60px;
}
#quickTable .edit input{
  display: none;
}
#quickTable.editable .edit span{
  display: none;
}
#quickTable.editable .edit input{
  display: block;
}
.rtl-btn{
  background: transparent;
  border: 0pc;
  text-transform: capitalize;
  margin-right: 23px;
  color: #12467B;
  font-size: 15px;
  font-weight: bold;
  float: right;
  width: 100%;
  text-align: right;
  margin-top: 15px;
}

 /* theme-color-css */
 .themebg {
    display: flex;
    align-items: center;
    padding: 7px;
    border: solid 2px #eee;
    border-radius: 6px;
}
.themebg .child_theme:last-child{margin-right: 0px;}
 .child_theme {
  width: auto;
  height: auto;
  margin-right:10px;
  line-height: 0;
}
.grdients-color {
  background: -webkit-linear-gradient(left, #1D85D6 50%,#1F4AAD 50%);
  width: 40px;
  height: 40px;
  display: block;
  border: #fff solid 2px;
  cursor: pointer;
}
.grdients-color.c2{
  background: -webkit-linear-gradient(left, #FFB68F 50%,#FE927B 50%);
}
.grdients-color.c3{
  background: -webkit-linear-gradient(left, #37ac36 50%,#379877 50%);
}
.grdients-color.c4{
  background: -webkit-linear-gradient(left, #C1C1C1 50%,#848484 50%);
}
.child_theme input {
  display: none;
}
.child_theme label{margin: 0px; display: inline-block; position: relative;}
.child_theme span.checks {
  background: #1D85D6;
  position: absolute;
  top: -5px;
  right: -7px;
  padding: 8px;
  border-radius: 50%;
  display: none;
}
.child_theme span.checks::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 5px;
  width: 4px;
  height: 9px;
  border-bottom: #fff solid 2px;
  border-right: #fff solid 2px;
  -webkit-transform: rotate( 45deg);
          transform: rotate( 45deg);
}
.child_theme label input[type="radio"]:checked + div {
  border: #0034f0 solid 3px;
  display: inline-block;
  margin: 0px;
}
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #ececec !important;
}
.MuiTab-textColorInherit {
  color: #000 !important;
  opacity: 0.7;
}
#Dashboard_new_btn__2HHTO {
  background-color: #96bc33 !important;
}
/* .PrivateTabIndicator-colorSecondary-3,
.PrivateTabIndicator-colorSecondary-10,
.PrivateTabIndicator-colorSecondary-20,
.PrivateTabIndicator-colorSecondary-29 {
  background-color: #12467B !important;
} */
.dash-delete {
  background: #f20000;
  padding: 5px 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
}
/*end */

/* theme-color-css */
.side_panel.peach-theme {
  background-image: linear-gradient(#FFB68F, #FE927B);
}
.side_panel.peach-theme .upper_circle_side_panel{
  background-image: linear-gradient(#FFB68F, #FE927B);
}
.logout_btn.peach-theme{background-color: #e27059;}
.side_panel.peach-theme a.side_panel_btn {
  background: #e27059;
}

.side_panel.green-theme {
  background-image: linear-gradient(#37ac36, #379877);
}
.side_panel.green-theme .upper_circle_side_panel{
  background-image: linear-gradient(#37ac36, #379877);
}
.logout_btn.green-theme{background-color: #207054;}
.side_panel.green-theme a.side_panel_btn {
  background: #207054;
}

.side_panel.gray-theme {
  background-image: linear-gradient(#c1c1c1, #848484);
}
.side_panel.gray-theme .upper_circle_side_panel{
  background-image: linear-gradient(#c1c1c1, #848484);
}
.logout_btn.gray-theme{background-color: #434343;}
.side_panel.gray-theme a.side_panel_btn {
  background: #434343;
}
/* end */



@media screen and (max-width: 1280px) {
  #login_heading,
  .login_container,
  .register-box,
  #register_heading,
  .login-box {
    width: 40vw;
  }
  #footer {
    width: 100%;
  }
  .login_logo {
    max-width: 300px;
  }
  .welcome-text h2 {
    font-size: 200%;
  }
  .error_icon {
    top: -62px;
    left: 102%;
  }
}

@media screen and (max-width: 992px) {
  .login_container {
    margin: 20px auto;
  }

  #register_heading,
  #login_heading,
  .login-box,
  .register-box,
  .login_container {
    width: 60vw;
    margin: 0 auto;
  }
  .left,
  .register,
  #login_right,
  .login,
  #register_right,
  #forgot_right {
    height: auto;
    min-height: 100%;
    overflow: visible;
    width: 100%;
    display: block;
    box-sizing: border-box;
  }
  .lower_circle,
  .upper_circle {
    display: none;
  }
  #reset_heading {
    margin-top: 0;
  }
  .login_logo {
    position: relative;
    left: 0%;
    right: 0%;
    max-width: 300px;
    display: block;
  }
  .left {
    padding: 40px 0;
  }
  .welcome-text {
    padding-top: 40px;
  }
  .welcome-text h2 {
    font-size: 200%;
  }
  #forgot_right {
    margin-top: 20px;
  }
}

@media screen and (max-width: 800px) {
/* .ReactModalPortal{
  overflow-x: hidden;
} */
.ReactModal__Content{
  min-width: 90%!important;
  width: 100%;
}
.table-responsive{
  overflow-x: auto!important;
}
.custom_price_table{
  max-width: 767px!important;
  min-width: 767px !important;
}

}

@media screen and (max-width: 768px) {
  .error_icon {
    left: 92%;
  }
  #login_heading,
  .login_container,
  .login-box,
  .register-box,
  #register_heading {
    width: 90vw;
  }
  #login_heading,
  .login_container {
    margin: 10px auto;
  }
  .register-box,
  .login-box {
    padding: 10px;
    margin-top: 0;
  }
  #login_heading h4,
  #register_heading h4,
  #reset_heading h4 {
    font-size: 150%;
  }
  label {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  #login_submit_btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .inputs,
  .otp-input-group .inputs {
    font-size: 14px;
    padding: 8px 8px 8px 60px;
  }
  .send_otp,
  .inputs {
    height: 48px;
  }
  .input_icons {
    position: relative;
    top: -34px;
    left: 20px;
    width: 20px;
  }
}


/* dashboard */

  .Dashboard_dashboard__3XncO{
    display: flex;
  }
  .Dashboard_dashboard_main__rtOIY{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    /* min-width: 0; */
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }
  .Dashboard_page_content__1Iw6j {
    /* height: 100vh; */  
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  .Dashboard_flex_image__nZqJr{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 15px 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-top: 30px;
    grid-gap: 15px;
    gap: 15px;
  }
  .Dashboard_flex_image__nZqJr a i{
    vertical-align: top;
    font-size:14px;
  }
  .Dashboard_dashboard_main__rtOIY h1{
    font-size: 24px;
    font-family: 'nevis';
    font-weight: bold;
    font-style: normal;
    letter-spacing: 0px;
    color: #333333;
  }
  @media only screen and (max-width: 767px) {
    .Dashboard_dashboard_main__rtOIY h1{
      font-size: 18px;
    }
  }
  .Dashboard_dashboard_header__1strQ{
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    align-items: center;
  }
  @media only screen and (max-width: 767px) {
    .Dashboard_dashboard_header__1strQ{
      margin-top: 0px;
    }
    #Dashboard_simple-tabpanel-0__2G6Eh
    .Dashboard_MuiBox-root-10__12Mxt {
        padding: 10px 0;
    }
  }

/* welcome section csss */

.Dashboard_welcome_sec__37WBj{
  background-color: #E1F5FF;
  display: block;
  border-radius: 10px;
  padding: 30px 30px 30px;
  box-sizing: border-box;
  /* -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%); */
}
.Dashboard_welcome_row__16BgN{
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.Dashboard_welcome_col_left__37NWn h1{
  margin: 0;
  font: normal normal normal 24px/36px PT Sans;
}
.Dashboard_welcome_col_left__37NWn h1 span{
  font: bold normal normal 24px/24px PT Sans;
  display: inline-block;
  color: #12467B;
}
.Dashboard_welcome_col_left__37NWn p{
  color: #333333;
  font: normal normal normal 16px/20px PT Sans;
  margin-top: 6px;
}
.Dashboard_welcome_col_left__37NWn p span{
  display: inline-block;
  font: bold normal normal 16px/24px PT Sans;
  /* border-bottom: solid 2px #333333; */
  text-decoration: underline;
}
.Dashboard_welcome_col_left__37NWn, .Dashboard_welcome_col_right__W_4Q4{
  display: block;
  position: relative;
}
.Dashboard_welcome_col_right__W_4Q4 img{
  margin-top: -60px;
  padding-right: 30px;
}

.Dashboard_statistics_sec__38LcQ{
  display: block;
  box-sizing: border-box;
  margin-top: 30px;
}
.Dashboard_statistics_row__2lqgl{
  display: flex;
  justify-content: space-between;
  margin: 0 -20px;
}
.Dashboard_statistics_card__3J553{
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  margin: 0 20px;
  padding:20px 24px;
  display: block;
  width: 100%;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  margin-bottom: 1.5rem;
  background-color: #fff;
  border: 1px solid #edf2f9;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 6%); */
  /* -webkit-box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%);
  box-shadow: 0 7px 14px 0 rgb(65 69 88 / 10%), 0 3px 6px 0 rgb(0 0 0 / 7%); */
  height: 100%!important;
}

.Dashboard_card_row__3Hqxl{
  display: flex;
  justify-content: space-between;
}
.Dashboard_card_col_left__3K_Tx p, .Dashboard_card_col_right__9lcjz p{
  font: normal normal normal 14px/32px PT Sans;
  color: #808285;
  
}
.Dashboard_card_col_left__3K_Tx h4, .Dashboard_card_col_right__9lcjz h4{
  font: bold normal normal 28px/32px PT Sans;
  color: #222;
}
.Dashboard_card_col_right__9lcjz .Dashboard_ass_icon__2YhdV{
  height: 150px;
  width: 150px;
  position: absolute;
  right: -8px;
  bottom: -40px;
  opacity: 0.2;
}
  .Dashboard_showing_results__1-qP5{
    display: flex;
    color: #808285;
  }
  .Dashboard_showing_results__1-qP5 h5{
    font: normal normal normal 18px/60px PT Sans;
    letter-spacing: 0px;
    display: inline-block;
  }
  .Dashboard_showing_results__1-qP5 select{
    width: 60px;
    height: 35px;
    border: 1px solid #bababb;
    border-radius: 2px;
    font-size: 100%;
    color: #808285;
    padding: 6px;
    margin-left: 15px;
    align-self: center;
    border-radius: 4px;
  }
  
  #Dashboard_new_btn_container__2pGLG{
    position: relative;
  }
  
  #Dashboard_new_btn__3wCjt{
    background-color: #12467B;
    border: none;
    /* height: 45px; */
    display: flex;
    align-self: center;
    border-radius: 6px;
    margin-right: 5px;
    padding: 10px 14px;
    font: bold normal normal 16px/14px PT Sans;
    letter-spacing: 0.8px;
    color: #ffffff;
    box-shadow: 0px 15px 20px #00000028;
    transition: all 1s;
    -webkit-transition: all 1s; 
  }
  #Dashboard_new_btn__3wCjt:hover{
    background-color: #042452;
    box-shadow: none;
  }
  @media only screen and (max-width: 767px) {
    #Dashboard_new_btn__3wCjt {
      padding:6px 10px;
      font: bold normal normal 14px/14px PT Sans;
    }
    #Dashboard_new_btn__3wCjt img {
      height: 14px;
    }
  }

  .Dashboard_video-icon__1xUIN {
    width: 48px;
    height: 48px;
}
@media only screen and (max-width: 600px) {
  .Dashboard_video-icon__1xUIN {
    width: 30px;
  }
}
  #Dashboard_new_btn__3wCjt img{
    margin-right: 10px;
    height: 16px;
  }

  .Dashboard_drop_down__1UKoF{
    position: absolute;
    top: 44px;
    right: 0;
    /* height: 125px; */
    min-width: 180px;
    box-shadow: 0 1.5rem 4rem rgb(22 28 45 / 15%);
    border-radius: 10px;
    padding: 10px 15px;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: none;
    margin: 10px;
  }
  
  .Dashboard_show_drop_down__1D6sb{
    display: flex;
  }
  
  .Dashboard_drop_down_btn__18J7U{
    font: normal normal normal 16px/31px PT Sans;
    letter-spacing: 0px;
    color: #444;
    font-weight: 600;
    padding:4px 15px;
    text-decoration: none;
    /* background-color: #f2f2f2; */
    margin-bottom: 10px;
    border-radius: 6px;
    width: 100%;
    box-sizing: border-box;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  }
  .Dashboard_drop_down_btn__18J7U:hover{
    background-color: #f5f5f5;
  }
  .Dashboard_drop_down_btn__18J7U:last-child{
    margin-bottom: 0;
  }
  .Dashboard_drop_down_btn__18J7U:hover{
    color: #12467B;
  }
 
  .Dashboard_table_head__26bjj{
    margin-top: 0;
    width: 100%;
    background-color: #f9fbfd;
    border-radius: 6px;
    height: 60px;
    display: flex;
    color: #333333;
    /* font: normal normal normal 16px/60px PT Sans; */
    letter-spacing: 0px;
    align-items: center;
    border: 1px solid #edf2f9;
  }
  
  .Dashboard_table_head__26bjj span img{
    margin-left: 10px;
  }
  
  .Dashboard_table_head_name__WWzkF{
    width: 20%;
  }
  @media only screen and (max-width: 767px) {
    .Dashboard_table_head_name__WWzkF{
      width: auto;
      padding: 12px;
    }
  }
  
  .Dashboard_table_head_date__2PvOt{
    margin-right: 6vw;
  }
  
  .Dashboard_table_head_company__1lac9{
    margin-right: 4vw;
  }
  
  .Dashboard_table_head_notes__cpth5{
    margin-right: 5vw;
  }
  
  /* table row */
  .Dashboard_table_row__2KF5H{
    margin-top: 15px;
    width: 100%;
    /* background-color: #F8F8F8; */
    /* border-radius: 15px; */
    height: 60px;
    /* line-height: 60px; */
    border-radius: 6px;
    display: flex;
    align-items: center;
    color: #4d4d4d;
    font: normal normal normal 16px PT Sans;
    letter-spacing: 0px;
    border: 1px solid #edf2f9;
    box-shadow: 0px 2px 6px 0px rgba(82, 63, 105, 0.06);
    -webkit-box-shadow: 0px 2px 6px 0px rgba(82, 63, 105, 0.06);
  }
  
  .Dashboard_table_row__2KF5H:hover{
    background-color: #f9fbfd;
  }
 
  .Dashboard_table_row_name__2tWMk{
    margin-left: 4vw;
    margin-right: 20vw;
  }
  
  .Dashboard_table_row_date__2Dz20{
    margin-right: 5vw;
  }
  
  .Dashboard_table_row_company__3UzdJ{
    margin-right: 5vw;
  }
  
  .Dashboard_table_row_notes__1anro{
    /* margin-right: 6vw; */
    /* display: flex;
    align-items: center; */
    /* width: 31px; */
    position: relative;
    --c : none;
  }
  
  .Dashboard_table_row_notes__1anro:hover{
    --c: block;
  }
  
  .Dashboard_table_row_note__3FFYV{
    position: absolute;
    right: 0px;
    top: 12px;
    width: 229px;
    z-index: 1;
    height: 64px;
    text-align: left;
    font: normal normal normal 14px/22px PT Sans;
    letter-spacing: 0px;
    color: #808285;
    box-shadow: 0px 0px 50px #44444428;
    border-radius: 8px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    display: var(--c);
  }
  
  .Dashboard_table_row_status__10BYb{
    color: #53B7E8;
  }
  
  .Dashboard_complete__7WAM2{
    color: #96BC33;
  }
  
  /* dashboard footer */
  
  .Dashboard_dashboard_footer__3f-AJ{
    margin-top: 40px;
    height: 60px;
    display: flex;
    margin-bottom: 40px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}
  
  .Dashboard_progress_bar__3z_on{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    left: 0;
    width: 400px;
    align-self: center;
  }
  
  .Dashboard_progress__3oNrJ{
    background-color: #1957ac;
    width: 50%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
  .Dashboard_page_btn_container__1WmI2{
    /* position: relative; */
    /* width: 170px; */
    /* height: 60px; */
    /* right: -17vw; */
    border-radius: 8px;
    background-color: #F1F1F1;
    display: flex;
    align-items: center;
    
    /* font-size: 400%; */
    /* justify-content: center; */
  }
  
  .Dashboard_page_btn_container__1WmI2 h1{
    font-size: 40%;
  }

  

  /* @media screen and (max-width: 1750px) {
    .table_row{
      font: normal normal normal 18px/55px PT Sans;
      letter-spacing: 0px;
      height: 40px;
    }

    .table_head{
      font: normal normal normal 18px/55px PT Sans;
    letter-spacing: 0px;
    height: 40px;
    
    }

    .table_head img{
      width: 10px;
    }

    .table_row_notes img{
      width: 18px;
    }

    .table_head_notes{
      margin-right: 6vw;
    }



    .table_head_date{
      margin-right: 5vw;
    }

    .table_head_company{
      margin-right: 6vw;
    }

    .dashboard_footer{
      width: 94%;
    } 
  } */

  /* @media screen and (max-width: 1570px) {

    .dashboard_main h1{
      font: normal normal bold 25px/40px PT Sans;
    }

    .showing_results h5{
      font: normal normal normal 20px/32px PT Sans;
    }

    .showing_results select{
      width: 40px;
      height: 30px;
      font-size: 80%;
      padding-left: 0px;
      align-self: flex-start;
    }

    #new_btn_container{
      width: 100px;
      height: 35px;
      position: relative;
    }
    
    #new_btn{
      color: #FFFFFF;
      background-color: #F7941D;
      border: none;
      width: 100px;
      height: 35px;
      box-shadow: 0px 15px 20px #00000028;
      border-radius: 25px;
      font: normal normal normal 18px/25px PT Sans;
      letter-spacing: 0.96px;
    }
    
    #new_btn img{
      margin-right: 15px;
      width: 15px;
    }

    .drop_down{
      position: absolute;
      top: 40px;
      left: -65px;
      height: 95px;
      width: 180px;
      box-shadow: 0px 10px 30px #36363628;
      border-radius: 26px;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      display: none;
    }

    .show_drop_down{
      display: flex;
    }
    
    .drop_down_btn{
      font: normal normal normal 16px/25px PT Sans;
      letter-spacing: 0.96px;
      color: #2D0505;
      font-weight: bold;
      padding: 5px;
      text-decoration: none;
    }
    

    .table_row{
      font: normal normal normal 13px/42px PT Sans;
      letter-spacing: 0px;
    }

    .table_head{
      font: normal normal normal 13px/42px PT Sans;
    letter-spacing: 0px;
    }

    .dashboard_footer{
      width: 80%;
    }

   

    .table_row_note{
      width: 130px;
      height: 50px;
      font: normal normal normal 10px/15px PT Sans;
    }

    .table_row_company{
      margin-right: 7vw;
    }

    .table_row_name{
      margin-left: 2vw;
    }

    .dashboard_footer{
      margin-top: 100px;
      height: 60px;
      display: flex;
      justify-content: center;
      margin-bottom: 120px;
      width: 95%;
    }
    
    .progress_bar{
      position: relative;
      background-color: #F0F0F0;
      box-shadow: 0px 5px 10px #44444429;
      border-radius: 10px;
      height: 10px;
      left: 5vw;
      width: 350px;
      align-self: center;
      justify-self: center;
    }
    
    .progress{
      background-color: #F7941D;
      width: 20%;
      height: 10px;
      box-shadow: 0px 5px 10px #44444428;
      border-radius: 10px;
    }
    
    .page_btn_container{
      position: relative;
      width: 90px;
      height: 40px;
      right: -20vw;
      top: 10px;
      border-radius: 8px;
      background-color: #F1F1F1;
      display: flex;
      font-size: 250%;
      justify-content: center;
    }
    
    .page_btn_container h1{
      font-size: 30%;
    }
  
  } 

  @media screen and (max-width: 1485px) {
    .table_row{
      font: normal normal normal 10px/30px PT Sans;
      letter-spacing: 0px;
    }

    .table_head{
      font: normal normal normal 10px/30px PT Sans;
    letter-spacing: 0px;
    }

    .dashboard_footer{
      width: 80%;
    }
    
    .table_row_company{ 
       margin-right: 7vw;
      text-align: center;
      justify-content: center;
       align-items: center;
      line-height: 60px;
    }
  } */
@media screen and (max-width:992px) {
.Dashboard_welcome_col_right__W_4Q4 img {
  margin-top: -20px;
  padding-right: 0;
  max-height: 80px;
}
.Dashboard_welcome_col_left__37NWn h1, .Dashboard_welcome_col_left__37NWn h1 span {
  /* font: normal normal normal 24px/36px PT Sans; */
  font-size: 24px;
  font-family: 'nevis';
  font-weight: bold;
  font-style: normal;
}
.Dashboard_welcome_sec__37WBj {
    padding: 10px 10px 0;
}
.Dashboard_welcome_col_left__37NWn p span{
  font-size: 14px;
}
.Dashboard_page_content__1Iw6j{
  margin: 14px;
  padding: 14px;
}
.Dashboard_card_col_right__9lcjz .Dashboard_ass_icon__2YhdV {
  height: 80px;
  width: 80px;
  right: -8px;
  bottom: -10px;
}
.Dashboard_statistics_card__3J553{
  padding: 10px;
}
.Dashboard_table_head_name__WWzkF {
  font-size: 14px;
}
}
@media screen and (max-width:767px) {

  .Dashboard_dashboard_footer__3f-AJ, .Dashboard_card_row__3Hqxl, .Dashboard_statistics_row__2lqgl, .Dashboard_statistics_card__3J553{
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
  .Dashboard_page_btn_container__1WmI2{
    justify-content: center;
    margin-top: 20px;
  }
  .Dashboard_welcome_col_right__W_4Q4 img {
    margin-top: 0;
    padding-right: 0;
    max-height: 60px;
}
.Dashboard_welcome_col_left__37NWn p {
    font: normal normal normal 18px/16px PT Sans;
    font-size: 13px;
}
.Dashboard_welcome_col_left__37NWn h1, .Dashboard_welcome_col_left__37NWn h1 span {
    font: normal normal normal 18px/36px PT Sans;
}

}

.Settings_settings_error_msg__2-rsp{
    color: #f20000;
    font: normal normal normal 14px/70px PT Sans;
    letter-spacing: 0px;
    display: none;
    height: 0px;
    position: relative;
    top: 40px;
  }

  .Settings_settings_inputs__2jwgC {
    height:   40px;
  }

  .Settings_settings_inputs__2jwgC {
    border: solid 2px #eee;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    margin-top: 0px;
    padding: 8px 14px 8px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    box-sizing: border-box;
    }
    .Settings_settings_inputs__2jwgC:focus {
      background-color: transparent;
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  }
  
.Settings_settings_error__30Djm{
    box-shadow: 0px 3px 6px #FF8282;
    border: 1px solid #f20000;
  }
  
.Settings_settings_error_icon__-iqrO{
    position: relative;
    top: -42px;
    left: 28vw;
    width: 25px;
    display: none;
  }
  
.Settings_settings_display_error__2n5XP{
    display: inline;
  }
  
/* settings */

.Settings_settings__1lbJO{
    display: flex;
    flex-wrap: wrap;
  }
  
.Settings_settings_main__VL53n{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: margin 0.15s ease-in-out;
  /* min-width: 0; */
  flex-grow: 1;
  min-height: calc(100vh);
  margin-left: 260px;
  box-sizing: border-box;
  background-color: #f5f5f5;
  }
  .Settings_page_content__35o55 {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  .Settings_settings_main__VL53n::-webkit-scrollbar{
    display: none;
  }
  
.Settings_settings_container__LoKif{
    display: flex;
    flex-direction: column;
    position: relative;
  }
  
  
.Settings_settings_main__VL53n h1{
  font: normal normal bold 24px/60px PT Sans;
  letter-spacing: 0px;
  color: #333333;
  }
  
.Settings_settings_form__1CDBp{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    /* margin: 0 -20px; */
    justify-content: space-between;
    border: solid 1px #eee;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
  }
  
.Settings_settings_form_div__3gayq{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 0;
    width: 44%;
    margin: 0 20px;
    box-sizing: border-box;
    justify-content: space-between;
    box-sizing: border-box;
    margin-bottom: 30px;
}

  .Settings_container_class__2SYKl{
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 80px;
  }
  
.Settings_settings_form_div__3gayq .Settings_error_icon__29mIp{
    top: -40px;
    left: 28vw;
  }
  .Settings_flex_image__2vXta{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
    grid-gap: 15px;
    gap: 15px;
  }

 .Settings_flex_image__2vXta a i{
    vertical-align: top;
    font-size:14px;
  }
  
.Settings_settings_form_div__3gayq .Settings_error_msg__1Duf-{
    top: 50px;
  }
  
  
.Settings_settings_form__1CDBp .Settings_inputs__1Zlsp{
    width: 28.5vw !important;
    margin-right: 90px;
    padding-left: 20px;
  }
  
  .Settings_action_button__3AbDz{
    display: block;
    margin-top: 50px;
    width: 100%;
    text-align: center;
  }


.Settings_settings_submit_btn__2iynU, .Settings_settings_upload_btn__15iKq{
  /* box-shadow: 0px 15px 20px #00000028; */
  box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
  transition: box-shadow .25s ease,-webkit-transform .25s ease;
  transition: box-shadow .25s ease,transform .25s ease;
  transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
  border-radius: 8px;
  padding: 8px 16px;
  font: bold normal normal 18px/31px PT Sans;
  letter-spacing: 0.96px;
  text-decoration: none;
  transition: 0.3s;
  }
  .Settings_settings_submit_btn__2iynU, .Settings_settings_upload_btn__15iKq{
    margin: 0 10px 10px 10px;
  }
  .Settings_settings_submit_btn__2iynU  {
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    padding: 8px 50px;
  }
  .Settings_settings_upload_btn__15iKq  {
    color: #666;
    background-color: #f2f2f2;
    border: dashed 1px #979696;
  }
  .Settings_settings_submit_btn__2iynU:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
  .Settings_settings_submit_btn__2iynU:hover, .Settings_settings_upload_btn__15iKq:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    -webkit-transform: translate3d(0,-3px,0);
            transform: translate3d(0,-3px,0);
}
  
.Settings_settings_fade__2MbcL{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0.8;
    z-index: 200;
    display: none;
  }
  
.Settings_settings_popup__23Vtm{
    position: absolute;
    left: calc(50% - 23vw);
    top: calc(50% - 32vh);
    align-self: center; 
    width: 46vw;
    height: 64vh;
    justify-self: center;
    box-shadow: 0px 0px 50px #44444428;
    border-radius: 30px;
    background-color: #ffffff;
    opacity: 1;
    z-index: 201;
    display: none;
  }
  
.Settings_settings_popup_open__18NhZ{
    display: block;
  }
  
.Settings_drag_and_drop__20Hr3{
    border: 1px dashed #707070;
    position: relative;
    width: 90%;
    height: 70%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: center;
  }
  
.Settings_drag_and_drop_img__1ReR5{
    position: relative;
    width: 130px;
    top: 0px;
    align-self: center;
  }
  
.Settings_uploaded_logo__2r7Xq{
  position: absolute;
  width: 100%;
  height: 100%;
  left: calc(50% - 45%);
  z-index: 12;
  display: none;
  /* border: 2px solid red; */
  left: 0;
  right: 0;
  }

.Settings_drag_and_drop__20Hr3 div{
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* width: 100%; */
  }
  

  .Settings_cross_btn__3Dhgl{
    position: absolute;
    width: 25px;
    height: 25px;
    /* border: 2px solid red; */
    /* background-color: red; */
    border-radius: 1000px;
    box-shadow: 0px 3px 15px #7A7A7A23;
    top: 5%;
    right: 3%;
    z-index: 150;
    cursor: pointer;
    display: none;
  }

  .Settings_image_uploaded__LGeQ4{
    display: block;
  }
  .Settings_image_uploaded_new__3xCr9{
    display: block;
    width: 40%;
    height: auto;
    margin: 0 auto;
  }
  
.Settings_drag_and_drop__20Hr3 h5{
    position: relative;
    top: -40px;
    font: normal normal normal 21px/27px PT Sans;
    letter-spacing: 0px;
    color: #D8D8D8;
    align-self: center;
  }
  
.Settings_drag_and_drop__20Hr3 p{
    font: normal normal normal 18px/27px PT Sans;
    letter-spacing: 0px;
    color: #808285;
    position: relative;
    top: 0px;
    align-self: center;
  }
  .Settings_drag_and_drop__20Hr3 p{
    margin-bottom: 20px;
  }
  
/* .settings_upload_btn{
    color: #FFFFFF;
    background-color: #F7941D;
    border: none;
    width: 200px;
    height: 45px;
    box-shadow: 0px 15px 20px #00000028;
    border-radius: 21px;
    font: normal normal normal 24px/31px PT Sans;
    letter-spacing: 0.96px;
    align-self: center;
    position: relative;
    top: 50px;
  } */
  
.Settings_popup_btn_right__3mYbD{
    left: 0px;
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
  }
  
.Settings_popup_btn_left__3apeu{
    background-color: #777777;
    border: solid 1px #777777;
    padding-left: 50px;
    padding-right: 50px;
    
  }
  .Settings_popup_btn_left__3apeu:hover{
    background-color: #555555;
    border: solid 1px #555555;
  }
.Settings_popup_btn_container__2vYPu{
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 10px;
  }  

  /* @media screen and (max-width: 1872px){

    .settings_error_icon{
      left: 25vw;
    }

    .settings_main{
      padding-right: 0;
    }

    .settings_inputs{
      width: 26vw !important;
    }
  } */

  /* @media screen and (max-width: 1254px){
    .settings_form{
      display: flex;
      flex-direction: column;
    }

    .settings_form_div{
      width: 40%;
      align-self: center;
      position: relative;
      left: -0px;
    }
    .settings_main h1{
      width: 90%;
      text-align: center;
      margin-right: 0;
      align-self: center;
    }
  } */
  @media screen and (max-width:1100px){
    .Settings_settings_form__1CDBp{
      padding:10px  0px;
      /* width: 42%; */
    }
    .Settings_settings_form_div__3gayq{
      width: 42%; 
      margin-bottom: 10px;
    }
  }
  @media screen and (max-width:1023px){
  
    .Settings_settings_form_div__3gayq{
      display: block;
      width: 100%;
    }
    .Settings_page_content__35o55 {
      margin: 14px;
      padding: 14px;
  }
  /* .settings_form, .settings_form_div{
    margin: 10px 0;
  } */
}
/* quick assessment */

.Quick_assessment_quick_assessment__2RW8F{
    display: flex;
  }
  
  .Quick_assessment_quick_assessment_main__PUEcJ{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }
  .Quick_assessment_page_content__nRU3R {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  .Quick_assessment_quick_assessment_main__PUEcJ h1{
    font: normal normal bold 24px/60px PT Sans;
    letter-spacing: 0px;
    color: #333333;
  }
  .Quick_assessment_flex_image__1y9pL{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
    grid-gap: 15px;
    gap: 15px;
  }
  .Quick_assessment_flex_image__1y9pL a i{
    vertical-align: top;
    font-size:14px;
  }
  .Quick_assessment_text-center__2HdgQ{
    text-align: center;
  }
  .Quick_assessment_quick_assessment_row__MEb_J{
    display: flex;
    /* margin: 10px 0; */
    justify-content: space-between;
  }
  
  @media only screen and (max-width: 600px) {
    .Quick_assessment_quick_table_container__2ob5j .Quick_assessment_quick_assessment_row__MEb_J:first-child{
      flex-direction:column-reverse;
    }
    .Quick_assessment_quick_table_container__2ob5j .Quick_assessment_quick_assessment_row__MEb_J:nth-child(2){
      flex-direction:column;
    }
    .Quick_assessment_quick_table_container__2ob5j .Quick_assessment_quick_assessment_row__MEb_J:first-child .Quick_assessment_quick_left_side__3YgYD,
    .Quick_assessment_quick_table_container__2ob5j .Quick_assessment_quick_assessment_row__MEb_J:first-child .Quick_assessment_quick_right_side__ZsXGk,
    .Quick_assessment_quick_table_container__2ob5j .Quick_assessment_quick_assessment_row__MEb_J:nth-child(2) .Quick_assessment_quick_left_side__3YgYD,
    .Quick_assessment_quick_table_container__2ob5j .Quick_assessment_quick_assessment_row__MEb_J:nth-child(2) .Quick_assessment_quick_right_side__ZsXGk{
      width: 100%;
    }
    .Quick_assessment_quick_table_container__2ob5j .Quick_assessment_quick_assessment_row__MEb_J:first-child .Quick_assessment_quick_right_side__ZsXGk{
      margin-bottom: 10px;
    }
  }
  .Quick_assessment_quick_assessment_left__2EKOE{
    /* height: 60px; */
    /* min-height: 60px; */
    display: flex;
    color: #343434;
    font: normal normal normal 20px/28px PT Sans;
    letter-spacing: 0px;
    margin-bottom: 20px;
    /* padding-left: 30px; */
    border-radius: 8px;
    border:solid 1px #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    /* background-color: #f9f9f9; */
    box-sizing: border-box;
  }
  .Quick_assessment_quick_assessment_right__2TnSa{
    padding-right: 20px;
    padding-left: 20px;
    font-weight: bolder;
    /* min-height: 60px; */
    display: flex;
    color: #1b1b1b;
    font: normal normal bold 20px/28px PT Sans;
    letter-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 8px;
    border:solid 1px #edf2f9;
    box-shadow: 0 0.25rem 1rem rgb(18 38 63 / 3%);
    /* background-color: #f9f9f9; */
    box-sizing: border-box;
  }
  .Quick_assessment_quick_assessment_left__2EKOE, .Quick_assessment_quick_assessment_right__2TnSa
  {
    padding: 16px 20px;
  }
  
  .Quick_assessment_quick_assessment_right__2TnSa input{
    border: none;
    color: #343434;
    font: normal normal bold 20px/60px PT Sans;
    letter-spacing: 0px;
    width: 100%;
    /* background-color: #f9f9f9; */
  }
  
  
  .Quick_assessment_video-icon__3u8q3 {
    width: 25px !important;
    height: 25px !important;
    -webkit-filter: invert(73%) sepia(8%) saturate(3232%) hue-rotate(36deg) brightness(92%) contrast(94%) !important;
            filter: invert(73%) sepia(8%) saturate(3232%) hue-rotate(36deg) brightness(92%) contrast(94%) !important;
}
  .Quick_assessment_real_revenue__1fwXl{
    background-color: #E9E9E9;
  }
  
  .Quick_assessment_real_revenue_right__AH8l2{
    background-color: #e9e9e9;
    color: #999999;
  }
  
  .Quick_assessment_quick_left_side__3YgYD{
    width: 80%;
    display: flex;
    flex-direction: column;
    color: #343434;
  }
  
  .Quick_assessment_quick_right_side__ZsXGk{
    width:18%;
    display: flex;
    flex-direction: column;
  }
  
  .Quick_assessment_quick_table_container__2ob5j{
    display: block;
    /* display: flex;
    margin-top: 30px;
    justify-content: space-between; */
  }
  
  .Quick_assessment_quick_last__3MVjs{
    margin-bottom: 0px;
  }
  
  .Quick_assessment_quick_footer1__1Lkz6{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
  }
  
  .Quick_assessment_quick_footer2__3S3Oh{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .Quick_assessment_quick_active_btn__1gt5z{
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/31px PT Sans;
    letter-spacing: 0.96px;
  }
  .Quick_assessment_quick_active_btn__1gt5z:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
 
  .Quick_assessment_quick_inactive_btn__3PXLh{
    color: #FFFFFF;
    background-color: #808285;
    border: none;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding: 8px 16px;
    /* height: 45px; */
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.46px; */
  }
  .Quick_assessment_quick_inactive_btn__3PXLh:focus, .Quick_assessment_quick_inactive_btn__3PXLh:hover,
  .Quick_assessment_quick_active_btn__1gt5z:focus, .Quick_assessment_quick_active_btn__1gt5z:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    -webkit-transform: translate3d(0,-3px,0);
            transform: translate3d(0,-3px,0);
}
  .Quick_assessment_activate_quick_btn__VSnVD{
    background-color: #F7941D ;
  }
  
  .Quick_assessment_quick_btn_red__KYaZc{
    background-color: #f20000;
    color: #fff!important;
  }
  
  .Quick_assessment_quick_btn_green__27zOK{
    background-color: #96BC33;
  }
  .Quick_assessment_color_black__2OPym{
    color: #000;
  }

  /* @media screen and (max-width: 1800px){
    .quick_active_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .quick_inactive_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .quick_assessment_main{
      padding-right: 0;
    }
  } */

  /* @media screen and (max-width: 1520px){
    .quick_active_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .quick_inactive_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .quick_assessment_main{
      padding-right: 0;
    }
  } */

  @media screen and (max-width: 1209px){
    
    .Quick_assessment_quick_active_btn__1gt5z{
      font: normal normal normal 15px/20px PT Sans;
    }
    .Quick_assessment_quick_inactive_btn__3PXLh{
      font: normal normal normal 15px/20px PT Sans;
    }
    .Quick_assessment_quick_assessment_main__PUEcJ{
      padding-right: 0;
    }
    .Quick_assessment_real_revenue_right__AH8l2{
      display: block;
      text-align: center;
    }
    .Quick_assessment_quick_assessment_left__2EKOE, .Quick_assessment_quick_assessment_right__2TnSa
    {
      padding: 8px 12px;
    }
    .Quick_assessment_quick_assessment_left__2EKOE, .Quick_assessment_quick_assessment_right__2TnSa, .Quick_assessment_quick_assessment_right__2TnSa input{
      font: normal normal normal 16px/24px PT Sans;
    }

  } 
  @media screen and (max-width: 992px){
    .Quick_assessment_page_content__nRU3R{
      margin: 20px;
      padding:20px;
    }
    .Quick_assessment_quick_active_btn__1gt5z, .Quick_assessment_quick_inactive_btn__3PXLh{
      margin: 10px;
    }
    .Quick_assessment_page_content__nRU3R{
      height: auto;
    }
    .Quick_assessment_quick_assessment_right__2TnSa{
      padding-right: 4px;
      padding-left: 4px;
    }
    .Quick_assessment_quick_assessment_right__2TnSa input, .Quick_assessment_quick_assessment_righ__-Wiv_ div{
      text-align: center;
      font-size: 16px;
    }
    
  }
  @media screen and (max-width: 767px){
    .Quick_assessment_quick_footer1__1Lkz6, .Quick_assessment_quick_footer2__3S3Oh{
      display: block;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .Quick_assessment_quick_active_btn__1gt5z, .Quick_assessment_quick_inactive_btn__3PXLh{
      display: block;
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
    
    .Quick_assessment_quick_assessment_right__2TnSa{
      padding-right: 0vw;
      padding-left: 0px;
      height:auto;
      justify-content: center;
    }
    .Quick_assessment_quick_assessment_right__2TnSa input, .Quick_assessment_quick_assessment_right__2TnSa{
      text-align: center;
      display: block;
    }
  }
/* footer */

.footer{
    position: absolute;
    bottom: 0px;
    /* border: 2px solid green; */
    width: 100%;
    height: 450px;
    overflow: hidden;
    z-index: 1;
    display: none;
    left: 0;
    right: 0;
  }
  
  .first_circle{
    background-image: linear-gradient(#1D457B, #1957AC);
    width: 800px;
    height: 800px;
    border-radius: 10000px;
    position: absolute;
    bottom: -400px;
    left: -400px;
    z-index: -2;
    -webkit-transform: rotate(-80deg);
            transform: rotate(-80deg);
    box-shadow: 0px -20px 50px #4B7ABB4D;
  }
  
  .second_circle{
    background-image: linear-gradient(#1D457B, #1957AC);
    width: 1200px;
    height: 1200px;
    border-radius: 10000px;
    position: absolute;
    bottom: -900px;
    left: 100px;
    z-index: -1;
    -webkit-transform: rotate(-100deg);
            transform: rotate(-100deg);
    box-shadow: 0px -20px 50px #4B7ABB4D;
  }
  
  .third_circle{
    background-image: linear-gradient(#1D457B, #1957AC);
    width: 800px;
    height: 800px;
    border-radius: 10000px;
    position: absolute;
    bottom: -550px;
    left: 1000px;
    z-index: -2;
    -webkit-transform: rotate(40deg);
            transform: rotate(40deg);
    box-shadow: 0px -20px 50px #4B7ABB4D;
  }
  
/* client info */

.Client_info_client_info__2lNfD{
    display: flex;
    width: 100%;
  }
  
  .Client_info_client_info_main__1RH4i{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }

  /* .client_info_main::-webkit-scrollbar{
    display: block;
  } */
  .Client_info_page_content__1WncA {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  
  /* .client_info_form_container{
    width: 90%;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 0px 50px #4B7ABB4C;
    border-radius: 50px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
  } */
  
  .Client_info_client_info_form_header__39IIb{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    grid-gap: 15px;
    gap: 15px;
  }
  .Client_info_client_info_form_header__39IIb a i{
    vertical-align: top;
    font-size:14px;
  }
  .Client_info_client_info_form_header__39IIb h4{
    font: normal normal bold 24px/60px PT Sans;
    letter-spacing: 0px;
    color: #333333;
  }
  .Client_info_PhoneInput__26LhL .Client_info_PhoneInputInput__2K1Ty, .Client_info_PhoneInput__26LhL .Client_info_PhoneInputInput__2K1Ty div {
    border:none !important;
  }
  
  /* .client_info_inputs{
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #808285;
    border-radius: 10px;
    width: 18vw;
    height: 40px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 110%;
  } */
  
  /* .client_info_select{
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px;
    width: 20vw;
    height: 60px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 110%;
  } */
  .Client_info_client_info_select__qavwg,  .Client_info_client_info_inputs__2EY2Y{
    border: solid 2px #eee;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    margin-top: 0px;
    padding: 8px 14px 8px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    box-sizing: border-box;
  }
  .Client_info_client_info_inputs__2EY2Y:focus, .Client_info_client_info_select__qavwg:focus, .Client_info_client_info_textarea__l3j-3:focus {
    /* border: 1px solid #1957AC; */
    background-color: transparent;
    /* box-shadow: 0px 0px 4px #00000028; */
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  }

  .Client_info_video-icon__2euis {
    width: 48px;
    height: 48px;
}
  .Client_info_client_info_form__2ovym{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: solid 1px #eee;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .Client_info_client_info_row__3lcLB{
    /* border: 2px solid red; */
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    /* padding-top: 20px; */
    margin: 0 -15px 20px;
    box-sizing: border-box;
  }
  
  .Client_info_client_info_input_container__2g8Gy{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 15px;
    /* margin: auto; */
  }
  
  .Client_info_client_info_radio__3HPVM{
    height: 35px;
    width: 35px;
    background-color: red;
    color: green;
    margin-right: 10px;
  }
  
  .Client_info_client_info_input_container__2g8Gy div{
    display: flex;
    align-items: center;
    font: normal normal normal 20px/26px PT Sans;
    letter-spacing: 0px;
    color: #808285;
  }
  
  .Client_info_client_info_textarea__l3j-3{
    width:100%; 
    height: 130px ;
    font: normal normal normal 16px/31px PT Sans;
    letter-spacing: 0px;
    color: #808285;
    /* border: 0.5px solid #808285; */
    border: solid 2px #eee;
    border-radius: 10px; 
    padding: 10px;
    box-sizing: border-box;
  }
  

  .Client_info_client_info_footer__fAGmo{
    margin-top: 40px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
  }

  .Client_info_progress_bar__L732X{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    left: 0;
    width: 200px;
    justify-self: center;
  }
  
  .Client_info_progress__1FYOI{
    background-color: #1957ac;
    width: 60%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
  .Client_info_client_info_submit_btn__24g3H{
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 16px/24px PT Sans;
    /* letter-spacing: 0.96px; */
  }
  .Client_info_client_info_submit_btn__24g3H:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    -webkit-transform: translate3d(0,-3px,0);
            transform: translate3d(0,-3px,0);
  }

  /* error handling */

  .Client_info_client_info_error_msg__3hpnu{
    color: #f20000;
    font: normal normal normal 14px/75px PT Sans;
    letter-spacing: 0px;
    display: none;
    height: 0px;
    position: relative;
    top: 40px;
  }
  
  .Client_info_client_info_error__KEef3{
    box-shadow: 0px 3px 6px #FF8282;
    border: 1px solid #f20000;
  }
  
  .Client_info_client_info_error_icon__3g80c{
    position: relative;
    top: -42px;
    left: 90%;
    width: 25px;
    display: none;
  }
  
  .Client_info_client_info_display_error__14i3r{
    display: inline;
  }
  
  .Client_info_primary_blue_btn__2BTm5{
    background-color: #12467B;
    border: none;
    /* height: 45px; */
    display: flex;
    align-self: center;
    border-radius: 6px;
    margin-right: 5px;
    padding: 8px 16px;
    font: bold normal normal 16px/24px PT Sans;
    letter-spacing: 0.8px;
    color: #ffffff;
    box-shadow: 0px 15px 20px #00000028;
    transition: all 1s;
    -webkit-transition: all 1s; 
  }
  .Client_info_primary_blue_btn__2BTm5:hover{
    background-color: #042452;
    box-shadow: none;
  }


  /* @media screen and (max-width: 1870px){
  
    .client_info_error_icon{
      left: 15vw ;
    }

    .client_info_footer{
      width: 80%;
    }
    .progress_bar{
      height: 10px;
      width: 350px;
      left: 12vw;
    }
    .progress{
      height: 10px;
    }
    .client_info_submit_btn{
      left: 320px;

    height: 40px;
    align-self: center;
    justify-self: flex-end;
    opacity: 1;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    background-color: #96BC33 ;
    width: 200px;
    border: none;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    }
  }

  @media screen and (max-width: 1490px){
    .client_info_footer{
      width: 70%;
    }
  }

  @media screen and (max-width: 1360px){
    .client_info_footer{
      width: 50%;
    }
    .client_info_submit_btn{
      font: normal normal normal 15px/36px PT Sans;
    }
  } */

  @media screen and (max-width: 1024px){
    .Client_info_page_content__1WncA{
      margin: 20px;
      padding: 20px;
    }
    .Client_info_client_info_row__3lcLB, .Client_info_client_info_input_container__2g8Gy,
    .Client_info_client_info_display_error__14i3r, label{
      display: block;
    }
    .Client_info_client_info_row__3lcLB{
      margin:0;
      padding-top: 0;
    }
    .Client_info_client_info_input_container__2g8Gy{
      margin: 15px 0;
    }
    .Client_info_client_info_select__qavwg,  .Client_info_client_info_inputs__2EY2Y {
      font-size: 16px;
    }
    label{
      font-size: 14px;
    }
    .Client_info_client_info_select__qavwg,  .Client_info_client_info_inputs__2EY2Y{
      padding: 10px 14px 10px 20px;
      height: auto;
    }

  }
/* analyzing report */

.Analyzing_report_analyzing_report__yA0nK{
    display: flex;
  }
  
  .Analyzing_report_analyzing_report_main__eKf81{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    /* min-width: 0; */
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }

  .Analyzing_report_analyzing_report_main__eKf81::-webkit-scrollbar{
    display: none;
  }
  .Analyzing_report_analyzing_report_form_container__1o5BZ{
    display: block;
  }
  /*.analyzing_report_form_container{
     width: 92%;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 0px 50px #4B7ABB4C;
    border-radius: 50px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px; 
   
  }*/
  .Analyzing_report_page_content__32NfJ{
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 10  0%; */
    box-sizing: border-box;
}
.Analyzing_report_video-icon__3rG3t {
  width: 48px;
  height: 48px;
}
  .Analyzing_report_analyzing_report_form__1idDw{
    display: flex;
    flex-direction: column ;
  }
  
  .Analyzing_report_analyzing_report_form_header__1YzQ9{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    grid-gap: 15px;
    gap: 15px;
  }
  .Analyzing_report_analyzing_report_form_header__1YzQ9 a i{
    vertical-align: top;
    font-size:14px;
  }
  .Analyzing_report_analyzing_report_runrate__1PlO4{
    margin-top: 10px;
  }
  
  .Analyzing_report_analyzing_report_form_header__1YzQ9 h4{
    font: normal normal 700 24px/60px PT Sans;
    letter-spacing: 0;
    color: #333;
  }
  
  .Analyzing_report_analyzing_report_row__vjrN3{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* padding-top: 20px; */
    /* margin: auto; */
  }
  
  .Analyzing_report_analyzing_report_input_container__13vQE{
    display: flex;
    flex-direction: column;
  }

  .Analyzing_report_analyzing_report_radio__1vYds{
    height: 35px;
    width: 35px;
    background-color: red;
    color: green;
    margin-right: 10px;
  }
  
  .Analyzing_report_analyzing_report_input__2KCuU
  {
    width: 18vw;
    margin-left: 70px;
    height: 60px;
    margin-top: 0px;
  }
  .Analyzing_report_analyzing_report_input__2KCuU,  .Analyzing_report_analyzing_report_select__lkvr9, .Analyzing_report_analyzing_report_textarea__2a0OO{
    /* border: 0.5px solid #808285;
    border-radius: 10px;
    width: 18vw;
    height: 30px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    margin-left: 70px;
    font-size: 110%; */
    border: solid 2px #eee;
    border-radius: 10px;
    /* width: 100%;*/
    padding: 8px 14px 8px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    box-sizing: border-box;
  }
  .Analyzing_report_analyzing_report_input__2KCuU:focus, .Analyzing_report_analyzing_report_select__lkvr9:focus, .Analyzing_report_analyzing_report_textarea__2a0OO:focus {
    background-color: transparent;
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  }
  
  .Analyzing_report_analyzing_report_input_container__13vQE label{
    font: bold normal normal 18px/31px PT Sans;
    letter-spacing: 0px;
    color: #424242;
    margin-bottom: 10px;
  }

  .Analyzing_report_analyzing_report_input_holder__3K_cL{
    display: flex;
  }

  .Analyzing_report_analyzing_report_popup_input__1-ouT{
    border: 2px solid #808285;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 10px;
    width: calc(20vw - 30px) ;
    height: 50px;
    /* padding: 10px; */
    padding-left: 30px;
    font-size: 100%;
    font-weight: 600;
    /* border: 1px solid #53B7E8; */
    line-height: 44px;
  }
  
  .Analyzing_report_analyzing_report_input_container__13vQE div div{
    display: flex;
    align-items: center;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0px;
    color: #5f5f5f;
    /* margin-top: 25px; */
  }
  
  .Analyzing_report_analyzing_report-from-group__4udbe {
    margin-bottom: 10px;
  }

  .Analyzing_report_analyzing_report_input_container__13vQE div div span{
    margin-left: 15px;
  }
  
  .Analyzing_report_analyzing_report_checkbox__2GVNI{
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border: 1px solid #707070;
    border-radius: 5px;
  }
  
  input[type="file"] {
    display: none;
  }
  
  .Analyzing_report_custom_file_upload__30SRx {
    display: inline-block;
    padding: 6px 20px;
    cursor: pointer;
    /* box-shadow: 0px 5px 8px #00000029; */
    border: 1px dashed #757575;
    border-radius: 8px;
    /* width: 142px; */
    /* height: 40px; */
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
    margin-left: 40px;
    margin-top: 15px;
  }
  
  .Analyzing_report_custom_file_upload__30SRx em{
    font: normal normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    color: #828282;
    /* align-self: center;
    justify-self: center; */
  }
 
  .Analyzing_report_analyzing_report_textarea__2a0OO{
    width: 63vw ; 
    /* height: 130px ; */
    letter-spacing: 0px;
    font: normal normal normal 16px/24px PT Sans;
    /* 
    color: #808285;
    border: 0.5px solid #808285;
    border-radius: 10px; 
    padding: 10px; */
    margin-top: 10px;
  }
  
  .Analyzing_report_analyzing_report_select__lkvr9{
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px; */
    width: 20vw;
    height: 60px;
    /* margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 110%; */
  }
  
  .Analyzing_report_analyzing_report-from-group__4udbe{
    display: block;
  }

  .Analyzing_report_analyzing_report_radio__1vYds{
    width: 25px;
    height: 25px;
  }
  
  .Analyzing_report_analyzing_report_footer__9eTIq{
    margin-top: 60px;
    /* height: 60px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    width: 100%;
  }
  
  .Analyzing_report_analyzing_report_progress_bar__1WVQe{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    /* left: 0vw; */
    width: 500px;
    align-self: center;
    justify-self: center;
  }
  
  .Analyzing_report_analyzing_report_progress__395Vp{
    background-color: #1957ac;
    width: 50%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
  .Analyzing_report_analyzing_report_submit_btn__ejprx{
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.96px; */
    text-decoration: none;
  }
  .Analyzing_report_analyzing_report_submit_btn__ejprx:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
  .Analyzing_report_analyzing_report_previous_btn__35TqC{
    color: #FFFFFF;
    background-color: #808285;
    border:solid 1px #808285;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.96px; */
    text-decoration: none;
  }
  .Analyzing_report_analyzing_report_previous_btn__35TqC:hover{
    background-color: #363636;
    border:solid 1px #363636;
  }
  .Analyzing_report_analyzing_report_previous_btn__35TqC:hover,  .Analyzing_report_analyzing_report_submit_btn__ejprx:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    -webkit-transform: translate3d(0,-3px,0);
            transform: translate3d(0,-3px,0);
}
  /* .analyzing_report_footer{
    padding-bottom: 120px;
  } */

  .Analyzing_report_analyzing_report_row__vjrN3{
    margin-bottom: 20px;
  }
/* 
  @media screen and (max-width: 1870px){
    .analyzing_report_footer{
      width: 100%;
    }

    .analyzing_report_progress_bar{
      height: 10px;
      width: 350px;
    }
    .analyzing_report_progress{
      height: 10px;
    }
    .analyzing_report_submit_btn{
      left: 140px;
    box-shadow: 0px 15px 20px #00000028;
    border-radius: 21px;
    height: 40px;
    align-self: center;
    justify-self: flex-end;
    opacity: 1;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    background-color: #96BC33 ;
    width: 200px;
    border: none;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    }
    .analyzing_report_previous_btn{
      left: -140px;
    box-shadow: 0px 15px 20px #00000028;
    border-radius: 21px;
    height: 40px;
    align-self: center;
    justify-self: flex-end;
    opacity: 1;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0.56px;
    color: #FFFFFF;
    background-color: #96BC33 ;
    width: 200px;
    border: none;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    }
  } */

  @media screen and (max-width: 1600px){
    .Analyzing_report_analyzing_report_footer__9eTIq{
      width: 100%;
    }
  }

  @media screen and (max-width: 1360px){
    .Analyzing_report_analyzing_report_footer__9eTIq{
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px){
    .Analyzing_report_analyzing_report_footer__9eTIq, .Analyzing_report_analyzing_report_input_container__13vQE,
    .Analyzing_report_analyzing_report_popup_input__1-ouT, .Analyzing_report_analyzing_report_input_container__13vQE{
      width: 100%;
      box-sizing: border-box;
    }
    .Analyzing_report_analyzing_report_input__2KCuU,  .Analyzing_report_analyzing_report_select__lkvr9, .Analyzing_report_analyzing_report_textarea__2a0OO{
      width: 100% !important;
      box-sizing: border-box;
    }
    .Analyzing_report_analyzing_report_progress_bar__1WVQe{
      width: 250px;
    }
  }
/* worksheet */

.worksheet{
    display: flex;
  }
  
  .worksheet_main{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    /* min-width: 0; */
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }
  
  .worksheet_form_container{
    width: 100%;
    background-color: #fff;
    /* height: 85vh;
    min-height: 85vh;
    box-shadow: 0px 0px 50px #4B7ABB4C;
    padding: 30px;
    position: relative;
    z-index: 9;
    border-radius: 50px;
    box-sizing: border-box; */
    /*margin: auto;
    margin-top: 80px;
    margin-bottom: 80px; */
  }
  .page_content {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
    position: relative;
}
  .worksheet_form{
    display: flex;
    flex-direction: column ;
    width:100%;
    margin: auto;
  }
  
  .worksheet_form_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
  }
  
  .worksheet_form_header h4{
    font: normal normal bold 24px/38px PT Sans;
    letter-spacing: 0px;
    color: #333333;
  }

  .worksheet_form_header_h3{
    font: normal normal bold 24px/38px PT Sans;
    letter-spacing: 0.28px;
    color: #F7941D;
    align-self: center;
    /* display: none; */
  }
  
  .show_header_h3{
    display: block;
  }
  .flex_image{
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
    grid-gap: 15px;
    gap: 15px;
  }
  .flex_image a i{
    vertical-align: top;
    font-size:14px;
  }
  .worksheet_left{
    /* display: flex; */
    color: #343434;
    /* min-height: 28px; */
    display: block;
    letter-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: solid 1px #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    /* background-color: #f9f9f9; */
    box-sizing: border-box;
    /* padding: 16px 20px; */
  }
  .worksheet_left, .worksheet_right{
    font: normal normal normal 18px/24px PT Sans;
  }
  .spancol {
    display: block;
    width: 100%;
}
.spancol-note{
    font-size: 14px;
}
  .worksheet_left::-webkit-input-placeholder{
    color: #C9C9C9;
  }
  
  .worksheet_right{
    padding-right: 20px;
    padding-left: 20px;
    padding: 16px 20px;
    font-weight: bolder;
    /* min-height: 60px; */
    display: flex;
    color: #1b1b1b;
    /* font: normal normal bold 18px/28px PT Sans; */
    letter-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: solid 1px #edf2f9;
    box-shadow: 0 0.25rem 1rem rgb(18 38 63 / 3%);
    background-color: #f9f9f9;
    box-sizing: border-box;
  }
  
  .worksheet_right::-webkit-input-placeholder{
    color: #343434;
  }
  
  .worksheet_right input{
    border: none;
    color: #343434;
   
    letter-spacing: 0px;
    background-color: #F9F9F9 ;
    width: 100%;
  }
  
  .worksheet_right_side .worksheet_right input{
    font: normal normal normal 21px/60px PT Sans;
  }
  .worksheet_dark_left{
    background-color: #E9E9E9;
    color: #343434;
  }
  
  .worksheet_dark_right{
    background-color: #E9E9E9;
    color: #999999;
    
  }
  .worksheet_last, .worksheet_dark_right,
  .worksheet_right input, .worksheet_left, 
  .worksheet_right_side .worksheet_right{
    padding: 16px 20px;
  } 
  
  .worksheet_left_side{
    width: 80%;
    display: flex;
    flex-direction: column;
    color: #343434;
  }
  
  .worksheet_right_side{
    width: 18%;
    display: flex;
    flex-direction: column;
  }
  
  .worksheet_table_container{
   display: block;
}
.worksheet_table-row{
  display: flex;
  /* margin: 10px 0; */
  justify-content: space-between;
}

.worksheet_last{
    margin-bottom: 0px;
}
  
.worksheet_footer{
    margin-top: 40px;
    /* height: 60px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
}
  
.worksheet_submit_btn{
  color: #FFFFFF;
  background-color: #96BC33;
  border:solid 1px #96BC33;
  /* box-shadow: 0px 15px 20px #00000028; */
  box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
  transition: box-shadow .25s ease,-webkit-transform .25s ease;
  transition: box-shadow .25s ease,transform .25s ease;
  transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
  border-radius: 8px;
  padding: 8px 16px;
  font: bold normal normal 18px/24px PT Sans;
  /* letter-spacing: 0.96px; */
  text-decoration: none;
} 
.analyzing_report_previous_btn:hover{
    background-color: #363636;
    border:solid 1px #363636;
}
.worksheet_previous_btn{
    color: #FFFFFF;
    background-color: #808285;
    border:solid 1px #808285;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.96px; */
    text-decoration: none;
  }
   .worksheet_previous_btn:hover{
    background-color: #363636;
    border:solid 1px #363636;
  }
  .worksheet_submit_btn:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
  .worksheet_previous_btn:hover,  .worksheet_submit_btn:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    -webkit-transform: translate3d(0,-3px,0);
            transform: translate3d(0,-3px,0);
}

  .video-icon {
    width: 30px !important;
    height: 30px !important;
}
.worksheet_form_header h4{
  font: normal normal bold 24px/60px PT Sans;
  letter-spacing: 0px;
  color: #333333;
}

.worksheet_progress_bar{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    left: 7vw;
    width: 500px;
    align-self: center;
    justify-self: center;
  }
  
.worksheet_progress{
    background-color: #1957ac;
    width: 20%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
.worksheet5_btn1{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
  }
  
  .worksheet5_btn2{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
  }
  
  .worksheet_active_btn{
    color: #FFFFFF;
    background-color: #96BC33;
    border: solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.46px */
  }
  .worksheet_active_btn:hover{
    background-color: #60830a;
border: solid 1px #60830a;
  }
  .worksheet_inactive_btn{
    color: #FFFFFF;
    background-color: #808285;
    border: solid 1px #808285;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    padding: 8px 16px;
    /* height: 45px;
    box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,-webkit-transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease;
    transition: box-shadow .25s ease,transform .25s ease,-webkit-transform .25s ease;
    border-radius: 8px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.46px; */
  }
  .worksheet_inactive_btn:hover{
    background-color: #959595;
    border: solid 1px #959595;
  }
  .worksheet_active_btn:hover,  .worksheet_inactive_btn:hover{
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1) !important;
    -webkit-transform: translate3d(0,-3px,0);
            transform: translate3d(0,-3px,0);
  }
  .activate_worksheet_btn{
    background-color: #F7941D ;
  }
  
  .worksheet_btn_red{
    background-color: #f20000;
  }
  
  .worksheet_btn_green{
    background-color: #96BC33;
  }

  .worksheet_radio_container{
    margin: 40px 0px;
    font: normal normal normal 20px/26px PT Sans;
    letter-spacing: 0px;
    color: #575757;
  }

  .worksheet_radio_container label{
    font: normal normal normal 24px/31px PT Sans;
    letter-spacing: 0px;
    color: #575757;
  }

  .worksheet_radio{
    width: 30px;
    height: 30px;
  }

  .worksheet_radio_container div{
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .worksheet_radio_container div div{
    display: flex;
    justify-content: center;
  }

  .worksheet_radio_container div div span{
    margin-left: 5px;
  }


  .worksheet_fade{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0.8;
    z-index: 200;
    display: none;
  }
  
.worksheet_popup{
    position: absolute;
    left: calc(50% - 20vw);
    top: calc(50% - 29vh);
    align-self: center; 
    width: 40vw;
    height: 640px;
    justify-self: center;
    box-shadow: 0px 0px 50px #44444428;
    border-radius: 30px;
    background-color: #ffffff;
    opacity: 1;
    z-index: 201;
    display: none;
  }
  
  .worksheet_popup_btn{
  left: 140px;
  box-shadow: 0px 15px 20px #00000028;
  border-radius: 21px;
  height: 40px;
  align-self: center;
  justify-self: flex-end;
  opacity: 1;
  font: normal normal normal 18px/26px PT Sans;
  /* letter-spacing: 0.96px; */
  color: #FFFFFF;
  background-color: #96BC33 ;
  width: 200px;
  border: none;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  }


.worksheet_popup_open{
    display: block;
  }
  
.popup_btn_right{
    left: 0px;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
  
.popup_btn_left{
    left: 0px;
    width: auto;
    background-color: #53B7E8;
    padding-left: 50px;
    padding-right: 50px;
  }
  
.popup_btn_container{
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 50px;
  }  

  .worksheet_select{
    /* box-shadow: 0px 3px 6px #00000029; */
    border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px;
    width: 20vw;
    height: 50px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 120%;
    margin-bottom: 10px;
  }

  .worksheet_popup_data{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
    margin-top: 70px;
  }

  .worksheet_popup_data div label{
    display: flex;
    flex-direction: column;
    font: normal normal normal 24px/31px PT Sans;
    letter-spacing: 0px;
    color: #808285;
  }

  .worksheet_popup_input{
    border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px;
    width: calc(20vw - 30px) ;
    height: 50px;
    /* padding: 10px; */
    padding-left: 30px;
    font-size: 100%;
    border: 1px solid #53B7E8;
    line-height: 50px;
  }

  
 

  
  @media screen and (max-width: 1756px){
    /* .worksheet_left{
      font: normal normal normal 18px/60px PT Sans;
    } */
  }

  @media screen and (max-width: 1600px){
    /* .worksheet_left{
      font: normal normal normal 16px/60px PT Sans;
    }
    .worksheet_footer{
      width: 100%;
    }
    .worksheet_previous_btn{
      left: -60px;
    }
    .worksheet_submit_btn{
      left: 80px;
    } */
  }

  @media screen and (max-width: 1360px){
    /* .worksheet_left{
      font: normal normal normal 13px/60px PT Sans;
    }
    .worksheet_footer{
      width: 100%;
    }
    .worksheet_submit_btn{
      font: normal normal normal 15px/36px PT Sans;
      width: 160px;
    }
    .worksheet_previous_btn{
      font: normal normal normal 15px/36px PT Sans;
      width: 160px;
    } */
  }

  @media screen and (max-width: 1200px){
    .worksheet_left, .worksheet_right, .worksheet_right input{
      font: normal normal normal 16px/24px PT Sans;
      min-height: auto;
    }
    .worksheet_left, .worksheet_right, 
    .worksheet_right_side .worksheet_right{
      padding: 10px;
    }
    
    /* .worksheet_footer{
      width: 100%;
    }
    .worksheet_submit_btn{
      font: normal normal normal 13px/36px PT Sans;
      width: 130px;
    }
    .worksheet_previous_btn{
      font: normal normal normal 13px/36px PT Sans;
      width: 130px;
    }
    .worksheet_progress_bar{
      width: 250px;
    } */
  }
  @media screen and (max-width:1139px){
    .worksheet_footer{
      display: block;
      text-align: center;
    }
    .worksheet_progress_bar{
      width: 100%;
    }
    .worksheet_submit_btn, .worksheet_previous_btn,
    .worksheet5_btn1 button, .worksheet5_btn2 button,
    .worksheet_radio_container label{
      font: bold normal normal 14px/24px PT Sans;
    }
    .worksheet_submit_btn, .worksheet_previous_btn, .worksheet_footer{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .worksheet_submit_btn, .worksheet_previous_btn, 
    .worksheet_inactive_btn, .worksheet_active_btn{
      display: inline-block;
      margin: 10px;
    }
    .page_content {
      margin: 14px;
      padding: 14px;
    }
    .worksheet_radio_container {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  
  @media screen and (max-width: 1800px){
    /* .worksheet_active_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .worksheet_inactive_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .worksheet_assessment_main{
      padding-right: 0;
    } */
  }

  @media screen and (max-width: 1520px){
    /* .worksheet_active_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .worksheet_inactive_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .worksheet_assessment_main{
      padding-right: 0;
    } */
  }
