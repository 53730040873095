/* worksheet */

.worksheet{
    display: flex;
  }
  
  .worksheet_main{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    /* min-width: 0; */
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }
  
  .worksheet_form_container{
    width: 100%;
    background-color: #fff;
    /* height: 85vh;
    min-height: 85vh;
    box-shadow: 0px 0px 50px #4B7ABB4C;
    padding: 30px;
    position: relative;
    z-index: 9;
    border-radius: 50px;
    box-sizing: border-box; */
    /*margin: auto;
    margin-top: 80px;
    margin-bottom: 80px; */
  }
  .page_content {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
    position: relative;
}
  .worksheet_form{
    display: flex;
    flex-direction: column ;
    width:100%;
    margin: auto;
  }
  
  .worksheet_form_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
  }
  
  .worksheet_form_header h4{
    font: normal normal bold 24px/38px PT Sans;
    letter-spacing: 0px;
    color: #333333;
  }

  .worksheet_form_header_h3{
    font: normal normal bold 24px/38px PT Sans;
    letter-spacing: 0.28px;
    color: #F7941D;
    align-self: center;
    /* display: none; */
  }
  
  .show_header_h3{
    display: block;
  }
  .flex_image{
    display: flex;
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
    gap: 15px;
  }
  .flex_image a i{
    vertical-align: top;
    font-size:14px;
  }
  .worksheet_left{
    /* display: flex; */
    color: #343434;
    /* min-height: 28px; */
    display: block;
    letter-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: solid 1px #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    /* background-color: #f9f9f9; */
    box-sizing: border-box;
    /* padding: 16px 20px; */
  }
  .worksheet_left, .worksheet_right{
    font: normal normal normal 18px/24px PT Sans;
  }
  .spancol {
    display: block;
    width: 100%;
}
.spancol-note{
    font-size: 14px;
}
  .worksheet_left::-webkit-input-placeholder{
    color: #C9C9C9;
  }
  
  .worksheet_right{
    padding-right: 20px;
    padding-left: 20px;
    padding: 16px 20px;
    font-weight: bolder;
    /* min-height: 60px; */
    display: flex;
    color: #1b1b1b;
    /* font: normal normal bold 18px/28px PT Sans; */
    letter-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 8px;
    border: solid 1px #edf2f9;
    box-shadow: 0 0.25rem 1rem rgb(18 38 63 / 3%);
    background-color: #f9f9f9;
    box-sizing: border-box;
  }
  
  .worksheet_right::-webkit-input-placeholder{
    color: #343434;
  }
  
  .worksheet_right input{
    border: none;
    color: #343434;
   
    letter-spacing: 0px;
    background-color: #F9F9F9 ;
    width: 100%;
  }
  
  .worksheet_right_side .worksheet_right input{
    font: normal normal normal 21px/60px PT Sans;
  }
  .worksheet_dark_left{
    background-color: #E9E9E9;
    color: #343434;
  }
  
  .worksheet_dark_right{
    background-color: #E9E9E9;
    color: #999999;
    
  }
  .worksheet_last, .worksheet_dark_right,
  .worksheet_right input, .worksheet_left, 
  .worksheet_right_side .worksheet_right{
    padding: 16px 20px;
  } 
  
  .worksheet_left_side{
    width: 80%;
    display: flex;
    flex-direction: column;
    color: #343434;
  }
  
  .worksheet_right_side{
    width: 18%;
    display: flex;
    flex-direction: column;
  }
  
  .worksheet_table_container{
   display: block;
}
.worksheet_table-row{
  display: flex;
  /* margin: 10px 0; */
  justify-content: space-between;
}

.worksheet_last{
    margin-bottom: 0px;
}
  
.worksheet_footer{
    margin-top: 40px;
    /* height: 60px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
}
  
.worksheet_submit_btn{
  color: #FFFFFF;
  background-color: #96BC33;
  border:solid 1px #96BC33;
  /* box-shadow: 0px 15px 20px #00000028; */
  box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
  transition: box-shadow .25s ease,transform .25s ease;
  border-radius: 8px;
  padding: 8px 16px;
  font: bold normal normal 18px/24px PT Sans;
  /* letter-spacing: 0.96px; */
  text-decoration: none;
} 
.analyzing_report_previous_btn:hover{
    background-color: #363636;
    border:solid 1px #363636;
}
.worksheet_previous_btn{
    color: #FFFFFF;
    background-color: #808285;
    border:solid 1px #808285;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.96px; */
    text-decoration: none;
  }
   .worksheet_previous_btn:hover{
    background-color: #363636;
    border:solid 1px #363636;
  }
  .worksheet_submit_btn:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
  .worksheet_previous_btn:hover,  .worksheet_submit_btn:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    transform: translate3d(0,-3px,0);
}

  .video-icon {
    width: 30px !important;
    height: 30px !important;
}
.worksheet_form_header h4{
  font: normal normal bold 24px/60px PT Sans;
  letter-spacing: 0px;
  color: #333333;
}

.worksheet_progress_bar{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    left: 7vw;
    width: 500px;
    align-self: center;
    justify-self: center;
  }
  
.worksheet_progress{
    background-color: #1957ac;
    width: 20%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
.worksheet5_btn1{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
  }
  
  .worksheet5_btn2{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
  }
  
  .worksheet_active_btn{
    color: #FFFFFF;
    background-color: #96BC33;
    border: solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.46px */
  }
  .worksheet_active_btn:hover{
    background-color: #60830a;
border: solid 1px #60830a;
  }
  .worksheet_inactive_btn{
    color: #FFFFFF;
    background-color: #808285;
    border: solid 1px #808285;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 16px;
    /* height: 45px;
    box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.46px; */
  }
  .worksheet_inactive_btn:hover{
    background-color: #959595;
    border: solid 1px #959595;
  }
  .worksheet_active_btn:hover,  .worksheet_inactive_btn:hover{
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1) !important;
    transform: translate3d(0,-3px,0);
  }
  .activate_worksheet_btn{
    background-color: #F7941D ;
  }
  
  .worksheet_btn_red{
    background-color: #f20000;
  }
  
  .worksheet_btn_green{
    background-color: #96BC33;
  }

  .worksheet_radio_container{
    margin: 40px 0px;
    font: normal normal normal 20px/26px PT Sans;
    letter-spacing: 0px;
    color: #575757;
  }

  .worksheet_radio_container label{
    font: normal normal normal 24px/31px PT Sans;
    letter-spacing: 0px;
    color: #575757;
  }

  .worksheet_radio{
    width: 30px;
    height: 30px;
  }

  .worksheet_radio_container div{
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .worksheet_radio_container div div{
    display: flex;
    justify-content: center;
  }

  .worksheet_radio_container div div span{
    margin-left: 5px;
  }


  .worksheet_fade{
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: white;
    opacity: 0.8;
    z-index: 200;
    display: none;
  }
  
.worksheet_popup{
    position: absolute;
    left: calc(50% - 20vw);
    top: calc(50% - 29vh);
    align-self: center; 
    width: 40vw;
    height: 640px;
    justify-self: center;
    box-shadow: 0px 0px 50px #44444428;
    border-radius: 30px;
    background-color: #ffffff;
    opacity: 1;
    z-index: 201;
    display: none;
  }
  
  .worksheet_popup_btn{
  left: 140px;
  box-shadow: 0px 15px 20px #00000028;
  border-radius: 21px;
  height: 40px;
  align-self: center;
  justify-self: flex-end;
  opacity: 1;
  font: normal normal normal 18px/26px PT Sans;
  /* letter-spacing: 0.96px; */
  color: #FFFFFF;
  background-color: #96BC33 ;
  width: 200px;
  border: none;
  text-decoration: none;
  text-align: center;
  line-height: 40px;
  }


.worksheet_popup_open{
    display: block;
  }
  
.popup_btn_right{
    left: 0px;
    width: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
  
.popup_btn_left{
    left: 0px;
    width: auto;
    background-color: #53B7E8;
    padding-left: 50px;
    padding-right: 50px;
  }
  
.popup_btn_container{
    width: 80%;
    display: flex;
    justify-content: space-between;
    margin: auto;
    margin-top: 50px;
  }  

  .worksheet_select{
    /* box-shadow: 0px 3px 6px #00000029; */
    border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px;
    width: 20vw;
    height: 50px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 120%;
    margin-bottom: 10px;
  }

  .worksheet_popup_data{
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 80%;
    margin-top: 70px;
  }

  .worksheet_popup_data div label{
    display: flex;
    flex-direction: column;
    font: normal normal normal 24px/31px PT Sans;
    letter-spacing: 0px;
    color: #808285;
  }

  .worksheet_popup_input{
    border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px;
    width: calc(20vw - 30px) ;
    height: 50px;
    /* padding: 10px; */
    padding-left: 30px;
    font-size: 100%;
    border: 1px solid #53B7E8;
    line-height: 50px;
  }

  
 

  
  @media screen and (max-width: 1756px){
    /* .worksheet_left{
      font: normal normal normal 18px/60px PT Sans;
    } */
  }

  @media screen and (max-width: 1600px){
    /* .worksheet_left{
      font: normal normal normal 16px/60px PT Sans;
    }
    .worksheet_footer{
      width: 100%;
    }
    .worksheet_previous_btn{
      left: -60px;
    }
    .worksheet_submit_btn{
      left: 80px;
    } */
  }

  @media screen and (max-width: 1360px){
    /* .worksheet_left{
      font: normal normal normal 13px/60px PT Sans;
    }
    .worksheet_footer{
      width: 100%;
    }
    .worksheet_submit_btn{
      font: normal normal normal 15px/36px PT Sans;
      width: 160px;
    }
    .worksheet_previous_btn{
      font: normal normal normal 15px/36px PT Sans;
      width: 160px;
    } */
  }

  @media screen and (max-width: 1200px){
    .worksheet_left, .worksheet_right, .worksheet_right input{
      font: normal normal normal 16px/24px PT Sans;
      min-height: auto;
    }
    .worksheet_left, .worksheet_right, 
    .worksheet_right_side .worksheet_right{
      padding: 10px;
    }
    
    /* .worksheet_footer{
      width: 100%;
    }
    .worksheet_submit_btn{
      font: normal normal normal 13px/36px PT Sans;
      width: 130px;
    }
    .worksheet_previous_btn{
      font: normal normal normal 13px/36px PT Sans;
      width: 130px;
    }
    .worksheet_progress_bar{
      width: 250px;
    } */
  }
  @media screen and (max-width:1139px){
    .worksheet_footer{
      display: block;
      text-align: center;
    }
    .worksheet_progress_bar{
      width: 100%;
    }
    .worksheet_submit_btn, .worksheet_previous_btn,
    .worksheet5_btn1 button, .worksheet5_btn2 button,
    .worksheet_radio_container label{
      font: bold normal normal 14px/24px PT Sans;
    }
    .worksheet_submit_btn, .worksheet_previous_btn, .worksheet_footer{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .worksheet_submit_btn, .worksheet_previous_btn, 
    .worksheet_inactive_btn, .worksheet_active_btn{
      display: inline-block;
      margin: 10px;
    }
    .page_content {
      margin: 14px;
      padding: 14px;
    }
    .worksheet_radio_container {
      margin-top: 20px;
      margin-bottom: 10px;
    }
  }

  
  @media screen and (max-width: 1800px){
    /* .worksheet_active_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .worksheet_inactive_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .worksheet_assessment_main{
      padding-right: 0;
    } */
  }

  @media screen and (max-width: 1520px){
    /* .worksheet_active_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .worksheet_inactive_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .worksheet_assessment_main{
      padding-right: 0;
    } */
  }