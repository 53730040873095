/* @import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@font-face {
  font-family: 'nevis';
  src: url('./fonts/nevis-Bold.woff2') format('woff2'),
      url('./fonts/nevis-Bold.woff') format('woff'),
      url('./fonts/nevis-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}


* {
  margin: 0;
  padding: 0;
  outline: none;
  font-family: 'PT Sans', sans-serif;
}
a, a:hover, a:focus{
  color: #12467B;
  text-decoration: none;
}

.login {
  display: flex;
  /* height: 100%; */
  height: 100vh;
}
.text-danger{
  color: #f20000;
}

.left {
  position: relative;
  height: 100vh;
  /* min-height: 710px; */
  overflow: hidden;
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(#1d457b, #1957ac);
}
.ReactModal__Overlay.ReactModal__Overlay--after-open{
  z-index: 9998;
}
.login_logo {
  position: absolute;
  top: 10%;
  /* left: 2%; */
  width: 100%;
  z-index: 100;
  /* right: 2%; */
  margin: 0 auto;
  max-width: 400px;
}

.add-btn-pos{
    display: flex;
    justify-content: flex-end;
    margin-top: 0;
    float: right;
    position: relative;
    top: 42px;
    z-index: 99;
}
@media only screen and (max-width: 600px) {
  .add-btn-pos {
    top: -10px;
  }
}
.mr-1{
  margin-right: 5px;
}
.mr-3{
  margin-right: 20px;
}
.upper_circle {
  position: absolute;
  top: -40%;
  width: 40vw;
  height: 40vw;
  border-radius: 10000000000px;
  background-image: linear-gradient(#1957ac, #1d457b);
}

.welcome-text h2 {
  color: #ffffff;
  font-size: 250%;
  text-align: center;
  font-family: "PT Sans", sans-serif;
  font-weight: 600;
}
.custom-typehead > div:first-child input {
  width: 1000%;
  border: solid 2px #eee;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  margin-top: 0px;
  padding: 8px 14px 8px 20px;
  font-size: 16px;
  letter-spacing: 1px;
  background-color: #fff;
  transition: all 1s;
  -webkit-transition: all 1s;
  box-sizing: border-box;
}
.custom-typehead .rbt-menu{
  background:#fff;
  border: 1px solid #ccc;
}
.custom-typehead .rbt-menu a {
  display: block;
  text-decoration: none;
  color: #000;
  padding: 5px 10px;
  border-bottom: 1px solid #ccc;
  font-size: 15px;
}
.remove-file{
  margin-left: 5px;
  background: transparent;
  border:1px solid #000;
  height:20px;
  width:20px;
  border-radius: 50px;
}
.lower_circle {
  position: absolute;
  bottom: -50%;
  width: 35vw;
  height: 35vw;
  border-radius: 10000000000px;
  background-image: linear-gradient(#1957ac, #1d457b);
  overflow: hidden;
}

#login_right {
  width: 60%;
  height: 100vh;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-box {
  width: 28vw;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 30px;
  padding: 40px 40px;
  margin-top: 30px;
}

.d-flex {
  display: flex;
}

button {
  cursor: pointer;
}

#login_heading {
  display: flex;
  justify-content: space-between;
  width: 28vw;
  /* margin-left: 180px; */
  /* margin-top: 23%; */
  margin-bottom: 15px;
}

#login_heading h4 {
  font-size: 200%;
  font-weight: bold;
  color: #333333;
  align-self: center;
  font-family: 'PT Sans', sans-serif;
}

#login_heading img,
#register_heading img {
  width: 8%;
  cursor: pointer;
}

.login_container {
  background-color: #fff;
  /* border: 2px solid red; */
  color: black;
  margin-top: 0px;
  width: 28vw;
  /* margin-left: 180px; */
}

form {
  display: flex;
  flex-direction: column;
}

label {
  align-self: start;
  font-size: 18px;
  color: #575757;
  margin-bottom: 10px;
  margin-top: 10px;
  font-weight: 600;
}

.inputs {
  /* box-shadow: 0px 3px 6px #00000029; */
  border: solid 2px #eee;
  border-radius: 10px;
  width: 100%;
  height: 60px;
  margin-top: 0px;
  padding: 8px 8px 8px 74px;
  font-size: 18px;
  letter-spacing: 1px;
  background-color: #fff;
  transition: all 1s;
  -webkit-transition: all 1s;
  box-sizing: border-box;
}

.inputs:focus {
  /* border: 1px solid #1957AC; */
  background-color: transparent;
  /* box-shadow: 0px 0px 4px #00000028; */
  box-shadow: 0 0 10px rgb(0 0 0 / 6%);
}
.assessment-download, .report-download{
  padding: 5px;
  margin-right: 10px;
  margin-top: 20px;
  background: transparent;
  border: 1px solid #000;
  border-radius: 5px;
  font-size: 14px;
  text-decoration: none;
}
.mt-3{
  margin-top: 10px;
}
.input_icons {
  position: relative;
  top: -42px;
  left: 24px;
  height: 24px;
  width: 24px;
}

.indicator {
  display: none !important;
}

.is-active .indicator {
  display: block !important;
}

#footer {
  margin-top: 0px;
  display: flex;
  justify-content: space-between;
  width: 28vw;
  font-size: 100%;
}

#radio_container {
  padding: 0;
  margin: 0;
}

#radio_btn {
  width: 10px;
  height: 10px;
  border: 1px solid #808285;
  border-radius: 5px;
  padding: 8px;
  -webkit-appearance: none;
  position: relative;
  top: 4px;
}

#radio_container span {
  margin-left: 10px;
  font-size: 90%;
}
.no-record{
  width: 100%;
  text-align: center;
  margin-top: 15px;
}
input[type="radio"]:checked {
  background: url(./images/tick.png) no-repeat center center;
  background-size: 15px 15px;
}
.custom_date_picker {
  width: 100%;
  padding-left: 15px;
}
.custom_date_picker label {
  display: block;
}
.custom_date_picker .custom-picker {
  width: 100%;
  height: 60px;
  border-radius: 5px;
}
.custom_date_picker .custom-picker .react-date-picker__wrapper {
  border-color: #eeeeee;
  border-radius: 10px;
}
.custom_date_picker .custom-picker .react-date-picker__wrapper .react-date-picker__inputGroup {
  padding-left: 10px;
}
.react-date-picker__inputGroup {
  padding: 10px 6px;
  border: 1px solid #eee;
}
.worksheet-exit-button{
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#footer a {
  color: #1957ac;
  /* text-decoration: none; */
  font: normal normal normal 16px/24px PT Sans;
  font-family: sans-serif;
  transition: all 1s;
  -webkit-transition: all 3s;
}

#footer a:hover {
  text-decoration: underline;
  color: #1d457b;
}

#login_submit_btn {
  background-color: #96bc33;
  border: none;
  width: 100%;
  align-self: center;
  box-shadow: 0px 15px 20px #00000028;
  border-radius: 6px;
  font: bold normal normal 20px/26px PT Sans;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-top: 40px;
  margin-bottom: 0px;
  transition: all 1s;
  -webkit-transition: all 1s;
  padding: 15px 0;
}

#login_submit_btn:hover {
  background-color: #59721b;
  box-shadow: none;
}

form p {
  font: normal normal normal 18px/24px PT Sans;
  letter-spacing: 0px;
  color: #808285;
  align-self: center;
  margin-top: 20px;
  /* padding-bottom: 120px; */
}

form p a {
  color: #1957ac;
  text-decoration: none;
  font: normal normal normal 18px/24px PT Sans;
}
.login-link,
.register-link {
  width: 98%;
  margin: auto;
  padding: 20px 30px;
  box-sizing: border-box;
}
.login-link p,
.register-link p {
  font: normal normal normal 18px/24px PT Sans;
  letter-spacing: 0px;
  color: #808285;
  margin-top: 0;
  text-align: right;
}
.login-link p a,
.register-link p a {
  color: #1957ac;
  text-decoration: none;
  font: normal normal normal 18px/24px PT Sans;
}

/* register page */

.register {
  display: flex;
  height: 100vh;
}
#forgot_right,
#register_right {
  width: 60%;
  min-height: 100%;
  background-color: #f5f5f5;
  margin: 0 auto;
  overflow-y: auto;
  box-sizing: border-box;
  padding-bottom: 40px;
}
#forgot_right {
  display: flex;
}
.register-box {

  width: 28vw;
  margin: auto;
  background-color: #fff;
  border-radius: 10px;
  padding: 40px 40px;
}
.react-tel-input .form-control {
  font-size: 18px !important;
  width: 100% !important;
}
#register_heading {
  display: flex;
  justify-content: space-between;
  width: 28vw;
  margin-left: 0;
  margin-top: 0;
  margin-bottom: 30px;
}

#register_heading h4 {
  font-size: 200%;
  font-weight: bold;
  color: #333333;
  align-self: center;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}
.worksheet_popup_width select{
  width: 100%;
}
.worksheet_popup_width .custom_date_picker{
  padding-left:0
}
#register_submit_btn,
#reset_submit_btn {
  background-color: #96bc33;
  border: none;
  /* height: 45px; */
  width: 100%;
  align-self: center;
  box-shadow: 0px 15px 20px #00000028;
  border-radius: 6px;
  font: bold normal normal 20px/26px PT Sans;
  letter-spacing: 0.8px;
  color: #ffffff;
  margin-top: 10px;
  margin-bottom: 0px;
  transition: all 1s;
  -webkit-transition: all 1s;
  padding: 15px 0;
}

#register_submit_btn:hover,
#reset_submit_btn:hover {
  background-color: #59721b;
  box-shadow: none;
}

.register_form label {
  margin-top: 2px;
}

/* form validation */

.error_msg {
  color: #f20000;
  font: normal normal normal 14px/70px PT Sans;
  letter-spacing: 0px;
  display: none;
  height: 0px;
  position: relative;
  top: 40px;
}

.error {
  box-shadow: 0px 0px 2px #ff8282;
  border: 1px solid #f20000;
}

.error_icon {
  position: relative;
  top: -64px;
  left: 101%;
  width: 20px;
  /* display: none; */
}

.page-iniate {
  background: transparent;
  border: 0;
}
.page-iniate{
    font-size: 40px;
    line-height: 20px;
}
.Toastify__toast.Toastify__toast--error,.toastBody  {
  z-index: 100000;
}
.pagenum span{
    font-size: 20px;
    line-height: 20px;
    display: inline-block;
}

.display_error {
  display: inline;
}
/* side panel */

.side_panel {
  background-image: linear-gradient(#1d457b, #1957ac);
  /* display: flex;
  justify-content: center; */
  overflow: hidden;
  width: 260px;
  height: 100vh;
  z-index: 1038;
}
.side_panel{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
}
.user-profile, .logo{
    display: block;
    width: 100%;
    text-align: center;
}
.logo_sidepanel {
    /* position: absolute; */
    width: 100%;
    max-width:80%;
    display: inline-block;
    z-index: 100;
    margin: 30px auto;
}

.upper_circle_side_panel {
    position: absolute;
    top: 0;
    width: 20vw;
    height: 20vw;
    border-radius: 10000000000px;
    background-image: linear-gradient(#1d457b, #1957ac);
    transform: rotate(-150deg);
    z-index: -9;
}
.user_avatar {
    /* position: absolute; */
    width: 80px;
    height: 80px;
    object-fit: contain;
    /* top: 16%; */
    z-index: 101;
    border-radius: 100%;
    border: 1px solid #000000;
    -webkit-box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
}

.peach-theme .user_avatar, .gray-theme .user_avatar {
  border: 1px solid #000000;
}

.text-center {
  text-align: center;
}

.w-100 {
  width: 100%;
  display: block;
}
.video-icon{
    width: 48px;
    height: 48px;
  }
.user_name {
    font: normal normal bold 18px/31px PT Sans;
    letter-spacing: 0px;
    color: #ffffff;
    text-shadow: 0px 5px 6px #00000029;
    font-size: 100%;
    margin-top: 10px;
}
.peach-theme .user_name, .gray-theme .user_name{
  color: #000;
}
.side_panel_btn_container {
    margin-top: 50px;
    width: 100%;
    position: relative;
    box-sizing: border-box;
} 

.side_panel_btn {
    position: relative;
    display: flex;
    align-items: center;
    overflow: hidden;
    margin-bottom: 30px;
    text-decoration: none;
    padding: 8px 20px;
    margin: 20px 10px;
    border-radius: 6px;
    box-sizing: border-box;
    -webkit-box-shadow: 0px 15px 50px 0px rgb(82 63 105 / 15%);
    box-shadow: 0px 15px 50px 0px rgb(82 63 105 / 15%);
    transition: 0.3s;
    -webkit-transition: 0.3s;
    background-color: #404244;
}
.blue-theme .side_panel_btn{
  background-color: #0c355e;
}
.side_panel_btn:hover{
    background-color: #042452;
}

.button{
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .375rem;
    color: #161c2d;
    cursor: pointer;
    display: inline-block;
    font-size: 1.0625rem;
    font-weight: 600;
    line-height: 1.6;
    padding: .8125rem 1.25rem;
    text-align: center;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    vertical-align: middle;
}
.btn-green{
    background-color: #96BC33;
    border:solid 1px #96BC33;
}
.btn-green:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
}
.side_panel.green-theme+div .worksheet_form_header_h3{
  color:#60830a
}
.side_panel.blue-theme+div .worksheet_form_header_h3{
  color:#1957ac
}
.side_panel.peach-theme+div .worksheet_form_header_h3{
  color:#e27059
}
.side_panel.gray-theme+div .worksheet_form_header_h3{
  color:#434343
}
.lift{
    transition: box-shadow .25s ease,transform .25s ease;
}
.lift:focus, .lift:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    transform: translate3d(0,-3px,0);
}

.indicator {
    /* border: 2px solid green; */
    width: 6px;
    height: 50px;
    border-radius: 2px;
    background-color: #96BC33;
    visibility: hidden;
    position: absolute;
    z-index: 1;
    left: 0;
}

.active_tab {
  visibility: visible;
}

.side_panel_btn_icon {
    position: relative;
    width: 22px;
    height: 20px;
    display: inline-block;
}

.side_panel_btn_text {
  font: normal normal normal 18px/31px PT Sans;
  letter-spacing: 0px;
  color: #ffffff;
  text-shadow: 0px 5px 6px #00000029;
  margin-left: 10px;
}

.logout_btn {
    position: absolute;
    bottom: 0;
    display: flex;
    z-index: 105;
    width: 100%;
    height: 50px;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
    text-decoration: none;
    background-color: #12467B;
    padding: 10px 0;
}

.lower_circle_side_panel {
  position: absolute;
  bottom: -50px;
  left: 0%;
  width: 24vw;
  height: 25vw;
  border-radius: 10000000000px;
  /* background-image: linear-gradient(#1957ac, #1d457b); */
  /* background-image: linear-gradient(#1957ac, #1d457b); */


  overflow: hidden;
  z-index: -9;
}
/* reset password page */

#reset_heading {
  display: flex;
  justify-content: space-between;
  /* margin-top: 15%; */
  margin-top: 0;
  margin-bottom: 20px;
}

#reset_heading h4 {
  font-size: 200%;
  font-weight: bold;
  color: #333333;
  align-self: center;
  font-family: "PT Sans", sans-serif;
  font-weight: bold;
}
.otp-input-group {
  width: 100%;
  position: relative;
}

.otp-input-group .inputs {
  padding: 8px 100px 8px 74px;
}
.send_otp {
  position: absolute;
  top: 0px;
  right: 0;
  height: 60px;
  border-radius: 0px 10px 10px 0px;
  padding: 0 14px;
  background-color: #53b7e8;
  color: #ffffff;
  border: none;
  font: 600 normal normal 18px/40px PT Sans;
  letter-spacing: 0px;
}

/* #quickTable td,
#quickTable th {
  padding: 10px;
  border: solid 1px #e8e8e8;
}
#quickTable{
  width: 100%;
  border-collapse: collapse;
  
}
#quickTable th {
    background-color: #f2f2f2;
}
.ReactModalPortal button{
  width: 24px;
  height: 24px;
  border-radius: 100px;
  border: solid 1px #f5f5f5;
  font-weight: 600;
}
.ReactModal__Overlay{
  z-index: 9999;
} */

/* @media screen and (max-width: 1570px) {
  .side_panel {
    width: 18%;
  }
  .logo_sidepanel {
    width: 55%;
  }
  .user_avatar {
    width: 60px;
    height: 60px;
  }
  .user_name {
    font: normal normal bold 18px/24px PT Sans;
  }
  .indicator {
    height: 30px;
    width: 5px;
  }
  .side_panel_btn_icon {
    width: 35px;
    top: 4px;
    margin-left: 20px;
  }
  .side_panel_btn_text {
    font: normal normal normal 20px/26px PT Sans;
  }
} */



.analyeze_form_part{
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
}
.analyeze_form_part .analyzing_report-from-group {
  min-width: 31.2%;
  border: 1px solid #eee;
  padding: 10px 15px;
  margin-left: -1px;
  margin-top: -1px;
  display: flex !important;
    align-items: center !important;
    justify-content: space-between;
    display: -webkit-flex !important;
    -webkit-align-items: center !important;
    -webkit-justify-content: space-between;
}
.analyeze_form_part .analyzing_report-from-group.anlyze_last_report {
  width: 64.4%;
  margin-left: 1px;
  border-left: none;
}
.analyeze_form_part .analyzing_report-from-group > label {
  margin: 0;
  align-self: initial;
  -webkit-align-self: initial;
}
.analyeze_form_part .analyzing_report-from-group > div > span {
  margin-left: 5px;
}
.analyeze_form_part .analyzing_report-from-group > div > input#otherDocument {
  margin-left: 20px;
}
#analyzeTextarea > div textarea,
#analyzeTextarea > div label,
#analyzeTextarea > div {
  width: 100%;
}
div#analyzeFooter {
  margin-bottom: 0;
  margin-top: 30px;
}
.analyze_run_rate {
  display: flex;
  display: -webkit-flex;
  align-items: flex-end;
  -webkit-align-items: flex-end;
}
.analyze_run_rate > div {
  margin: 0;
  width: 33.33%;
}
.analyze_run_rate > div > div {
  width: 100%;
  padding-right: 20px;
}
.analyze_run_rate > div:last-child > div{
  padding-right: 0px;
}
.analyze_run_rate > div > div label {
  width: 100%;
  line-height: 26px;
}
.analyze_run_rate > div > div input,
.analyze_run_rate > div > div select {
  width: 100%;
}
.worksheet_popup_data.custom_modal_design {
  width: 90%;
  margin: 0;
  padding: 5%;
}
.popup_btn_container.custom_modal_cta {
  width: 90%;
  padding: 5%;
  margin: 0;
  padding-top: 10px;
  display: flex;
  justify-content: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
}
.custom_modal_cta button {
  margin: 0 10px;
}
.custom_modal_design > div > select, .custom_modal_design > div > input {
  width: 100%;
  padding-left: 10px;
}
.custom_modal_design > div .worksheet_popup_input {
  width:calc(100% - 10px);
  padding-left: 10px;
}
.custom_price_table {
  width: 100%;
  margin: 0;
  /* border: 1px solid #d2d2d2; */
  /* padding: 0px; */
  border-collapse: collapse;
  padding: 20px 10px;
  
}
.custom_price_table tbody th, .custom_price_table tbody td {
  /* border-top: 1px solid #ccc; */
  border-spacing: 0px;
}
.table_btn {
  display: flex;
  justify-content: center;
  display: -webkit-flex;
  -webkit-justify-content: center;
  padding: 20px 10px;
}
.analysis-table-report{
  background-color: #12467B;
  padding: 20px 10px;
}
.ReactModal__Content{
  padding: 0!important;
  border: 0!important;
  border-radius: 10px!important;
  box-shadow: 0 1.5rem 4rem rgba(22,28,45,.1)!important;
  min-width: 800px!important;
}

.custom_price_table tbody tr:nth-child(even) {background: #f9f9f9}
.custom_price_table tbody tr:nth-child(odd) {background: #FFFFFF}

/* .analysis-table tbody tr th, */
.analysis-table thead tr th{
  background-color: #f9f9f9;
}
.analysis-table thead tr th,
.analysis-table tbody tr th {
  text-align: left;
  
}
.analysis-table thead tr th,
.analysis-table tbody tr td,
.analysis-table tbody tr th{
  padding: 16px 20px;
  font-size: 14px;
  border-top: 1px solid #ddd;
}

.table_btn a {
  display: block;
}
.table_btn a button, .table_btn a.report-download,.table_btn a button, .table_btn .report-download {
  padding: 10px 15px;
  font-size: 18px;
  background: #96BC33;
  border: none;
  color: #fff;
  text-align: center;
}
.ReactModal__Overlay .ReactModal__Content > button {
  top: 10px !important;
  right: 10px !important;
  padding: 6px !important;
  line-height: 8px;
  font-weight: 800;
  background-color: #fff !important;
    border: solid 2px #ddd!important;
    width: 24px;
    height: 24px;
    border-radius: 100px;
   
}
.note_image_icon:hover + div,.note_image_icon + div:hover{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 50px;
}
.undo_btn{
  display: block;
  margin-top: 10px;
  color: #FFFFFF;
  background-color: #f20 !important;
  border: none;
  width: -moz-fit-content;
  width: fit-content;
  padding: 8px 16px;
  /* height: 45px; */
  box-shadow: 0px 15px 20px #00000028;
  box-shadow: 0 0.5rem 1.5rem rgb(22 28 45 / 10%);
  transition: box-shadow .25s ease,transform .25s ease;
  border-radius: 8px;
  font: bold normal normal 18px/24px PT Sans;
  /* letter-spacing: 0.46px; */
}

#simple-menu{
  z-index: 9999 !important;
}
.mt-20{
  margin-top: 20px !important;
}
.ml-10{
  margin-left: 10px !important;
}
.p_m_button{
  display: flex;
  height: 35px;
  justify-content: center;
}
.p_m_button button{
  width: 30px;
  font-size: 22px;
  border-radius: 0px;
  margin-right: -1px;
  font-weight: bold;
}
.custom_exp .name,.custom_exp .value,.custom_exp .actual,.custom_exp .q1,.custom_exp .q2,.custom_exp .q3,.custom_exp .q4,.custom_exp .q5,.custom_exp .q6,.aq1,.aq2,.aq3,.aq4,.aq5,.aq6{
  border-radius: 6px;
  height: 13px;
  padding: 10px;
}
.custom_exp .value,.custom_exp .actual,.custom_exp .q1,.custom_exp .q2,.custom_exp .q3,.custom_exp .q4,.custom_exp .q5,.custom_exp .q6,.aq1,.aq2,.aq3,.aq4,.aq5,.aq6{
  width: 60px;
}
#quickTable .edit input{
  display: none;
}
#quickTable.editable .edit span{
  display: none;
}
#quickTable.editable .edit input{
  display: block;
}
.rtl-btn{
  background: transparent;
  border: 0pc;
  text-transform: capitalize;
  margin-right: 23px;
  color: #12467B;
  font-size: 15px;
  font-weight: bold;
  float: right;
  width: 100%;
  text-align: right;
  margin-top: 15px;
}

 /* theme-color-css */
 .themebg {
    display: flex;
    align-items: center;
    padding: 7px;
    border: solid 2px #eee;
    border-radius: 6px;
}
.themebg .child_theme:last-child{margin-right: 0px;}
 .child_theme {
  width: auto;
  height: auto;
  margin-right:10px;
  line-height: 0;
}
.grdients-color {
  background: -webkit-linear-gradient(left, #1D85D6 50%,#1F4AAD 50%);
  width: 40px;
  height: 40px;
  display: block;
  border: #fff solid 2px;
  cursor: pointer;
}
.grdients-color.c2{
  background: -webkit-linear-gradient(left, #FFB68F 50%,#FE927B 50%);
}
.grdients-color.c3{
  background: -webkit-linear-gradient(left, #37ac36 50%,#379877 50%);
}
.grdients-color.c4{
  background: -webkit-linear-gradient(left, #C1C1C1 50%,#848484 50%);
}
.child_theme input {
  display: none;
}
.child_theme label{margin: 0px; display: inline-block; position: relative;}
.child_theme span.checks {
  background: #1D85D6;
  position: absolute;
  top: -5px;
  right: -7px;
  padding: 8px;
  border-radius: 50%;
  display: none;
}
.child_theme span.checks::after {
  content: "";
  display: block;
  position: absolute;
  top: 1px;
  right: 5px;
  width: 4px;
  height: 9px;
  border-bottom: #fff solid 2px;
  border-right: #fff solid 2px;
  transform: rotate( 45deg);
}
.child_theme label input[type="radio"]:checked + div {
  border: #0034f0 solid 3px;
  display: inline-block;
  margin: 0px;
}
.MuiAppBar-colorPrimary {
  color: #fff;
  background-color: #ececec !important;
}
.MuiTab-textColorInherit {
  color: #000 !important;
  opacity: 0.7;
}
#Dashboard_new_btn__2HHTO {
  background-color: #96bc33 !important;
}
/* .PrivateTabIndicator-colorSecondary-3,
.PrivateTabIndicator-colorSecondary-10,
.PrivateTabIndicator-colorSecondary-20,
.PrivateTabIndicator-colorSecondary-29 {
  background-color: #12467B !important;
} */
.dash-delete {
  background: #f20000;
  padding: 5px 10px;
  color: #fff;
  border: none;
  border-radius: 5px;
}
/*end */

/* theme-color-css */
.side_panel.peach-theme {
  background-image: linear-gradient(#FFB68F, #FE927B);
}
.side_panel.peach-theme .upper_circle_side_panel{
  background-image: linear-gradient(#FFB68F, #FE927B);
}
.logout_btn.peach-theme{background-color: #e27059;}
.side_panel.peach-theme a.side_panel_btn {
  background: #e27059;
}

.side_panel.green-theme {
  background-image: linear-gradient(#37ac36, #379877);
}
.side_panel.green-theme .upper_circle_side_panel{
  background-image: linear-gradient(#37ac36, #379877);
}
.logout_btn.green-theme{background-color: #207054;}
.side_panel.green-theme a.side_panel_btn {
  background: #207054;
}

.side_panel.gray-theme {
  background-image: linear-gradient(#c1c1c1, #848484);
}
.side_panel.gray-theme .upper_circle_side_panel{
  background-image: linear-gradient(#c1c1c1, #848484);
}
.logout_btn.gray-theme{background-color: #434343;}
.side_panel.gray-theme a.side_panel_btn {
  background: #434343;
}
/* end */



@media screen and (max-width: 1280px) {
  #login_heading,
  .login_container,
  .register-box,
  #register_heading,
  .login-box {
    width: 40vw;
  }
  #footer {
    width: 100%;
  }
  .login_logo {
    max-width: 300px;
  }
  .welcome-text h2 {
    font-size: 200%;
  }
  .error_icon {
    top: -62px;
    left: 102%;
  }
}

@media screen and (max-width: 992px) {
  .login_container {
    margin: 20px auto;
  }

  #register_heading,
  #login_heading,
  .login-box,
  .register-box,
  .login_container {
    width: 60vw;
    margin: 0 auto;
  }
  .left,
  .register,
  #login_right,
  .login,
  #register_right,
  #forgot_right {
    height: auto;
    min-height: 100%;
    overflow: visible;
    width: 100%;
    display: block;
    box-sizing: border-box;
  }
  .lower_circle,
  .upper_circle {
    display: none;
  }
  #reset_heading {
    margin-top: 0;
  }
  .login_logo {
    position: relative;
    left: 0%;
    right: 0%;
    max-width: 300px;
    display: block;
  }
  .left {
    padding: 40px 0;
  }
  .welcome-text {
    padding-top: 40px;
  }
  .welcome-text h2 {
    font-size: 200%;
  }
  #forgot_right {
    margin-top: 20px;
  }
}

@media screen and (max-width: 800px) {
/* .ReactModalPortal{
  overflow-x: hidden;
} */
.ReactModal__Content{
  min-width: 90%!important;
  width: 100%;
}
.table-responsive{
  overflow-x: auto!important;
}
.custom_price_table{
  max-width: 767px!important;
  min-width: 767px !important;
}

}

@media screen and (max-width: 768px) {
  .error_icon {
    left: 92%;
  }
  #login_heading,
  .login_container,
  .login-box,
  .register-box,
  #register_heading {
    width: 90vw;
  }
  #login_heading,
  .login_container {
    margin: 10px auto;
  }
  .register-box,
  .login-box {
    padding: 10px;
    margin-top: 0;
  }
  #login_heading h4,
  #register_heading h4,
  #reset_heading h4 {
    font-size: 150%;
  }
  label {
    margin-bottom: 10px;
    margin-top: 10px;
  }
  #login_submit_btn {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .inputs,
  .otp-input-group .inputs {
    font-size: 14px;
    padding: 8px 8px 8px 60px;
  }
  .send_otp,
  .inputs {
    height: 48px;
  }
  .input_icons {
    position: relative;
    top: -34px;
    left: 20px;
    width: 20px;
  }
}

