/* analyzing report */

.analyzing_report{
    display: flex;
  }
  
  .analyzing_report_main{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    /* min-width: 0; */
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }

  .analyzing_report_main::-webkit-scrollbar{
    display: none;
  }
  .analyzing_report_form_container{
    display: block;
  }
  /*.analyzing_report_form_container{
     width: 92%;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 0px 50px #4B7ABB4C;
    border-radius: 50px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px; 
   
  }*/
  .page_content{
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 10  0%; */
    box-sizing: border-box;
}
.video-icon {
  width: 48px;
  height: 48px;
}
  .analyzing_report_form{
    display: flex;
    flex-direction: column ;
  }
  
  .analyzing_report_form_header{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    gap: 15px;
  }
  .analyzing_report_form_header a i{
    vertical-align: top;
    font-size:14px;
  }
  .analyzing_report_runrate{
    margin-top: 10px;
  }
  
  .analyzing_report_form_header h4{
    font: normal normal 700 24px/60px PT Sans;
    letter-spacing: 0;
    color: #333;
  }
  
  .analyzing_report_row{
    width: 100%;
    display: flex;
    justify-content: space-between;
    /* padding-top: 20px; */
    /* margin: auto; */
  }
  
  .analyzing_report_input_container{
    display: flex;
    flex-direction: column;
  }

  .analyzing_report_radio{
    height: 35px;
    width: 35px;
    background-color: red;
    color: green;
    margin-right: 10px;
  }
  
  .analyzing_report_input
  {
    width: 18vw;
    margin-left: 70px;
    height: 60px;
    margin-top: 0px;
  }
  .analyzing_report_input,  .analyzing_report_select, .analyzing_report_textarea{
    /* border: 0.5px solid #808285;
    border-radius: 10px;
    width: 18vw;
    height: 30px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    margin-left: 70px;
    font-size: 110%; */
    border: solid 2px #eee;
    border-radius: 10px;
    /* width: 100%;*/
    padding: 8px 14px 8px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    box-sizing: border-box;
  }
  .analyzing_report_input:focus, .analyzing_report_select:focus, .analyzing_report_textarea:focus {
    background-color: transparent;
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  }
  
  .analyzing_report_input_container label{
    font: bold normal normal 18px/31px PT Sans;
    letter-spacing: 0px;
    color: #424242;
    margin-bottom: 10px;
  }

  .analyzing_report_input_holder{
    display: flex;
  }

  .analyzing_report_popup_input{
    border: 2px solid #808285;
    background-color: #f5f5f5;
    color: #333;
    border-radius: 10px;
    width: calc(20vw - 30px) ;
    height: 50px;
    /* padding: 10px; */
    padding-left: 30px;
    font-size: 100%;
    font-weight: 600;
    /* border: 1px solid #53B7E8; */
    line-height: 44px;
  }
  
  .analyzing_report_input_container div div{
    display: flex;
    align-items: center;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0px;
    color: #5f5f5f;
    /* margin-top: 25px; */
  }
  
  .analyzing_report-from-group {
    margin-bottom: 10px;
  }

  .analyzing_report_input_container div div span{
    margin-left: 15px;
  }
  
  .analyzing_report_checkbox{
    height: 30px;
    width: 30px;
    margin-right: 10px;
    border: 1px solid #707070;
    border-radius: 5px;
  }
  
  input[type="file"] {
    display: none;
  }
  
  .custom_file_upload {
    display: inline-block;
    padding: 6px 20px;
    cursor: pointer;
    /* box-shadow: 0px 5px 8px #00000029; */
    border: 1px dashed #757575;
    border-radius: 8px;
    /* width: 142px; */
    /* height: 40px; */
    text-align: center;
    /* display: flex; */
    /* justify-content: center; */
    margin-left: 40px;
    margin-top: 15px;
  }
  
  .custom_file_upload em{
    font: normal normal normal 18px/24px PT Sans;
    letter-spacing: 0px;
    color: #828282;
    /* align-self: center;
    justify-self: center; */
  }
 
  .analyzing_report_textarea{
    width: 63vw ; 
    /* height: 130px ; */
    letter-spacing: 0px;
    font: normal normal normal 16px/24px PT Sans;
    /* 
    color: #808285;
    border: 0.5px solid #808285;
    border-radius: 10px; 
    padding: 10px; */
    margin-top: 10px;
  }
  
  .analyzing_report_select{
    /* box-shadow: 0px 3px 6px #00000029; */
    /* border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px; */
    width: 20vw;
    height: 60px;
    /* margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 110%; */
  }
  
  .analyzing_report-from-group{
    display: block;
  }

  .analyzing_report_radio{
    width: 25px;
    height: 25px;
  }
  
  .analyzing_report_footer{
    margin-top: 60px;
    /* height: 60px; */
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    width: 100%;
  }
  
  .analyzing_report_progress_bar{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    /* left: 0vw; */
    width: 500px;
    align-self: center;
    justify-self: center;
  }
  
  .analyzing_report_progress{
    background-color: #1957ac;
    width: 50%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
  .analyzing_report_submit_btn{
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.96px; */
    text-decoration: none;
  }
  .analyzing_report_submit_btn:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
  .analyzing_report_previous_btn{
    color: #FFFFFF;
    background-color: #808285;
    border:solid 1px #808285;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.96px; */
    text-decoration: none;
  }
  .analyzing_report_previous_btn:hover{
    background-color: #363636;
    border:solid 1px #363636;
  }
  .analyzing_report_previous_btn:hover,  .analyzing_report_submit_btn:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    transform: translate3d(0,-3px,0);
}
  /* .analyzing_report_footer{
    padding-bottom: 120px;
  } */

  .analyzing_report_row{
    margin-bottom: 20px;
  }
/* 
  @media screen and (max-width: 1870px){
    .analyzing_report_footer{
      width: 100%;
    }

    .analyzing_report_progress_bar{
      height: 10px;
      width: 350px;
    }
    .analyzing_report_progress{
      height: 10px;
    }
    .analyzing_report_submit_btn{
      left: 140px;
    box-shadow: 0px 15px 20px #00000028;
    border-radius: 21px;
    height: 40px;
    align-self: center;
    justify-self: flex-end;
    opacity: 1;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    background-color: #96BC33 ;
    width: 200px;
    border: none;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    }
    .analyzing_report_previous_btn{
      left: -140px;
    box-shadow: 0px 15px 20px #00000028;
    border-radius: 21px;
    height: 40px;
    align-self: center;
    justify-self: flex-end;
    opacity: 1;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0.56px;
    color: #FFFFFF;
    background-color: #96BC33 ;
    width: 200px;
    border: none;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    }
  } */

  @media screen and (max-width: 1600px){
    .analyzing_report_footer{
      width: 100%;
    }
  }

  @media screen and (max-width: 1360px){
    .analyzing_report_footer{
      width: 100%;
    }
  }

  @media screen and (max-width: 1200px){
    .analyzing_report_footer, .analyzing_report_input_container,
    .analyzing_report_popup_input, .analyzing_report_input_container{
      width: 100%;
      box-sizing: border-box;
    }
    .analyzing_report_input,  .analyzing_report_select, .analyzing_report_textarea{
      width: 100% !important;
      box-sizing: border-box;
    }
    .analyzing_report_progress_bar{
      width: 250px;
    }
  }