/* client info */

.client_info{
    display: flex;
    width: 100%;
  }
  
  .client_info_main{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }

  /* .client_info_main::-webkit-scrollbar{
    display: block;
  } */
  .page_content {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  
  /* .client_info_form_container{
    width: 90%;
    height: fit-content;
    background-color: #fff;
    box-shadow: 0px 0px 50px #4B7ABB4C;
    border-radius: 50px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 80px;
  } */
  
  .client_info_form_header{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    margin-bottom: 30px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    gap: 15px;
  }
  .client_info_form_header a i{
    vertical-align: top;
    font-size:14px;
  }
  .client_info_form_header h4{
    font: normal normal bold 24px/60px PT Sans;
    letter-spacing: 0px;
    color: #333333;
  }
  .PhoneInput .PhoneInputInput, .PhoneInput .PhoneInputInput div {
    border:none !important;
  }
  
  /* .client_info_inputs{
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #808285;
    border-radius: 10px;
    width: 18vw;
    height: 40px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 110%;
  } */
  
  /* .client_info_select{
    box-shadow: 0px 3px 6px #00000029;
    border: 0.5px solid #808285;
    color: #808080;
    border-radius: 10px;
    width: 20vw;
    height: 60px;
    margin-top: 0px;
    padding: 10px;
    padding-left: 30px;
    font-size: 110%;
  } */
  .client_info_select,  .client_info_inputs{
    border: solid 2px #eee;
    border-radius: 10px;
    width: 100%;
    height: 60px;
    margin-top: 0px;
    padding: 8px 14px 8px 20px;
    font-size: 16px;
    letter-spacing: 1px;
    background-color: #fff;
    transition: all 1s;
    -webkit-transition: all 1s;
    box-sizing: border-box;
  }
  .client_info_inputs:focus, .client_info_select:focus, .client_info_textarea:focus {
    /* border: 1px solid #1957AC; */
    background-color: transparent;
    /* box-shadow: 0px 0px 4px #00000028; */
    box-shadow: 0 0 10px rgb(0 0 0 / 6%);
  }

  .video-icon {
    width: 48px;
    height: 48px;
}
  .client_info_form{
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border: solid 1px #eee;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
  }
  
  .client_info_row{
    /* border: 2px solid red; */
    /* width: 100%; */
    display: flex;
    justify-content: space-between;
    /* padding-top: 20px; */
    margin: 0 -15px 20px;
    box-sizing: border-box;
  }
  
  .client_info_input_container{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 15px;
    /* margin: auto; */
  }
  
  .client_info_radio{
    height: 35px;
    width: 35px;
    background-color: red;
    color: green;
    margin-right: 10px;
  }
  
  .client_info_input_container div{
    display: flex;
    align-items: center;
    font: normal normal normal 20px/26px PT Sans;
    letter-spacing: 0px;
    color: #808285;
  }
  
  .client_info_textarea{
    width:100%; 
    height: 130px ;
    font: normal normal normal 16px/31px PT Sans;
    letter-spacing: 0px;
    color: #808285;
    /* border: 0.5px solid #808285; */
    border: solid 2px #eee;
    border-radius: 10px; 
    padding: 10px;
    box-sizing: border-box;
  }
  

  .client_info_footer{
    margin-top: 40px;
    height: 60px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
    width: 100%;
    box-sizing: border-box;
    align-items: center;
  }

  .progress_bar{
    position: relative;
    background-color: #F0F0F0;
    box-shadow: 0px 5px 10px #44444429;
    border-radius: 10px;
    height: 15px;
    left: 0;
    width: 200px;
    justify-self: center;
  }
  
  .progress{
    background-color: #1957ac;
    width: 60%;
    height: 15px;
    box-shadow: 0px 5px 10px #44444428;
    border-radius: 10px;
  }
  
  .client_info_submit_btn{
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 16px/24px PT Sans;
    /* letter-spacing: 0.96px; */
  }
  .client_info_submit_btn:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    transform: translate3d(0,-3px,0);
  }

  /* error handling */

  .client_info_error_msg{
    color: #f20000;
    font: normal normal normal 14px/75px PT Sans;
    letter-spacing: 0px;
    display: none;
    height: 0px;
    position: relative;
    top: 40px;
  }
  
  .client_info_error{
    box-shadow: 0px 3px 6px #FF8282;
    border: 1px solid #f20000;
  }
  
  .client_info_error_icon{
    position: relative;
    top: -42px;
    left: 90%;
    width: 25px;
    display: none;
  }
  
  .client_info_display_error{
    display: inline;
  }
  
  .primary_blue_btn{
    background-color: #12467B;
    border: none;
    /* height: 45px; */
    display: flex;
    align-self: center;
    border-radius: 6px;
    margin-right: 5px;
    padding: 8px 16px;
    font: bold normal normal 16px/24px PT Sans;
    letter-spacing: 0.8px;
    color: #ffffff;
    box-shadow: 0px 15px 20px #00000028;
    transition: all 1s;
    -webkit-transition: all 1s; 
  }
  .primary_blue_btn:hover{
    background-color: #042452;
    box-shadow: none;
  }


  /* @media screen and (max-width: 1870px){
  
    .client_info_error_icon{
      left: 15vw ;
    }

    .client_info_footer{
      width: 80%;
    }
    .progress_bar{
      height: 10px;
      width: 350px;
      left: 12vw;
    }
    .progress{
      height: 10px;
    }
    .client_info_submit_btn{
      left: 320px;

    height: 40px;
    align-self: center;
    justify-self: flex-end;
    opacity: 1;
    font: normal normal normal 18px/26px PT Sans;
    letter-spacing: 0.96px;
    color: #FFFFFF;
    background-color: #96BC33 ;
    width: 200px;
    border: none;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
    }
  }

  @media screen and (max-width: 1490px){
    .client_info_footer{
      width: 70%;
    }
  }

  @media screen and (max-width: 1360px){
    .client_info_footer{
      width: 50%;
    }
    .client_info_submit_btn{
      font: normal normal normal 15px/36px PT Sans;
    }
  } */

  @media screen and (max-width: 1024px){
    .page_content{
      margin: 20px;
      padding: 20px;
    }
    .client_info_row, .client_info_input_container,
    .client_info_display_error, label{
      display: block;
    }
    .client_info_row{
      margin:0;
      padding-top: 0;
    }
    .client_info_input_container{
      margin: 15px 0;
    }
    .client_info_select,  .client_info_inputs {
      font-size: 16px;
    }
    label{
      font-size: 14px;
    }
    .client_info_select,  .client_info_inputs{
      padding: 10px 14px 10px 20px;
      height: auto;
    }

  }