/* quick assessment */

.quick_assessment{
    display: flex;
  }
  
  .quick_assessment_main{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: margin 0.15s ease-in-out;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh);
    margin-left: 260px;
    box-sizing: border-box;
    background-color: #f5f5f5;
  }
  .page_content {
    /* height: 100vh; */
    margin: 30px;
    padding: 30px;
    background-color: #fff;
    border-radius: 10px;
    /* width: 100%; */
    box-sizing: border-box;
    display: block;
}
  .quick_assessment_main h1{
    font: normal normal bold 24px/60px PT Sans;
    letter-spacing: 0px;
    color: #333333;
  }
  .flex_image{
    display: flex;
/*    justify-content: space-between;*/
    align-items: center;
    padding: 0 20px;
    border-radius: 8px;
    background-color: #f5f5f5;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    margin-bottom: 30px;
    gap: 15px;
  }
  .flex_image a i{
    vertical-align: top;
    font-size:14px;
  }
  .text-center{
    text-align: center;
  }
  .quick_assessment_row{
    display: flex;
    /* margin: 10px 0; */
    justify-content: space-between;
  }
  
  @media only screen and (max-width: 600px) {
    .quick_table_container .quick_assessment_row:first-child{
      flex-direction:column-reverse;
    }
    .quick_table_container .quick_assessment_row:nth-child(2){
      flex-direction:column;
    }
    .quick_table_container .quick_assessment_row:first-child .quick_left_side,
    .quick_table_container .quick_assessment_row:first-child .quick_right_side,
    .quick_table_container .quick_assessment_row:nth-child(2) .quick_left_side,
    .quick_table_container .quick_assessment_row:nth-child(2) .quick_right_side{
      width: 100%;
    }
    .quick_table_container .quick_assessment_row:first-child .quick_right_side{
      margin-bottom: 10px;
    }
  }
  .quick_assessment_left{
    /* height: 60px; */
    /* min-height: 60px; */
    display: flex;
    color: #343434;
    font: normal normal normal 20px/28px PT Sans;
    letter-spacing: 0px;
    margin-bottom: 20px;
    /* padding-left: 30px; */
    border-radius: 8px;
    border:solid 1px #edf2f9;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    /* background-color: #f9f9f9; */
    box-sizing: border-box;
  }
  .quick_assessment_right{
    padding-right: 20px;
    padding-left: 20px;
    font-weight: bolder;
    /* min-height: 60px; */
    display: flex;
    color: #1b1b1b;
    font: normal normal bold 20px/28px PT Sans;
    letter-spacing: 0px;
    margin-bottom: 20px;
    border-radius: 8px;
    border:solid 1px #edf2f9;
    box-shadow: 0 0.25rem 1rem rgb(18 38 63 / 3%);
    /* background-color: #f9f9f9; */
    box-sizing: border-box;
  }
  .quick_assessment_left, .quick_assessment_right
  {
    padding: 16px 20px;
  }
  
  .quick_assessment_right input{
    border: none;
    color: #343434;
    font: normal normal bold 20px/60px PT Sans;
    letter-spacing: 0px;
    width: 100%;
    /* background-color: #f9f9f9; */
  }
  
  
  .video-icon {
    width: 25px !important;
    height: 25px !important;
    filter: invert(73%) sepia(8%) saturate(3232%) hue-rotate(36deg) brightness(92%) contrast(94%) !important;
}
  .real_revenue{
    background-color: #E9E9E9;
  }
  
  .real_revenue_right{
    background-color: #e9e9e9;
    color: #999999;
  }
  
  .quick_left_side{
    width: 80%;
    display: flex;
    flex-direction: column;
    color: #343434;
  }
  
  .quick_right_side{
    width:18%;
    display: flex;
    flex-direction: column;
  }
  
  .quick_table_container{
    display: block;
    /* display: flex;
    margin-top: 30px;
    justify-content: space-between; */
  }
  
  .quick_last{
    margin-bottom: 0px;
  }
  
  .quick_footer1{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
  }
  
  .quick_footer2{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .quick_active_btn{
    color: #FFFFFF;
    background-color: #96BC33;
    border:solid 1px #96BC33;
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    padding: 8px 16px;
    font: bold normal normal 18px/31px PT Sans;
    letter-spacing: 0.96px;
  }
  .quick_active_btn:hover{
    background-color: #60830a;
    border:solid 1px #60830a;
  }
 
  .quick_inactive_btn{
    color: #FFFFFF;
    background-color: #808285;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
    padding: 8px 16px;
    /* height: 45px; */
    /* box-shadow: 0px 15px 20px #00000028; */
    box-shadow: 0 .5rem 1.5rem rgba(22,28,45,.1);
    transition: box-shadow .25s ease,transform .25s ease;
    border-radius: 8px;
    font: bold normal normal 18px/24px PT Sans;
    /* letter-spacing: 0.46px; */
  }
  .quick_inactive_btn:focus, .quick_inactive_btn:hover,
  .quick_active_btn:focus, .quick_active_btn:hover {
    box-shadow: 0 1rem 2.5rem rgba(22,28,45,.1),0 .5rem 1rem -.75rem rgba(22,28,45,.1)!important;
    transform: translate3d(0,-3px,0);
}
  .activate_quick_btn{
    background-color: #F7941D ;
  }
  
  .quick_btn_red{
    background-color: #f20000;
    color: #fff!important;
  }
  
  .quick_btn_green{
    background-color: #96BC33;
  }
  .color_black{
    color: #000;
  }

  /* @media screen and (max-width: 1800px){
    .quick_active_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .quick_inactive_btn{
      font: normal normal normal 20px/26px PT Sans;
    }
    .quick_assessment_main{
      padding-right: 0;
    }
  } */

  /* @media screen and (max-width: 1520px){
    .quick_active_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .quick_inactive_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .quick_assessment_main{
      padding-right: 0;
    }
  } */

  @media screen and (max-width: 1209px){
    
    .quick_active_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .quick_inactive_btn{
      font: normal normal normal 15px/20px PT Sans;
    }
    .quick_assessment_main{
      padding-right: 0;
    }
    .real_revenue_right{
      display: block;
      text-align: center;
    }
    .quick_assessment_left, .quick_assessment_right
    {
      padding: 8px 12px;
    }
    .quick_assessment_left, .quick_assessment_right, .quick_assessment_right input{
      font: normal normal normal 16px/24px PT Sans;
    }

  } 
  @media screen and (max-width: 992px){
    .page_content{
      margin: 20px;
      padding:20px;
    }
    .quick_active_btn, .quick_inactive_btn{
      margin: 10px;
    }
    .page_content{
      height: auto;
    }
    .quick_assessment_right{
      padding-right: 4px;
      padding-left: 4px;
    }
    .quick_assessment_right input, .quick_assessment_righ div{
      text-align: center;
      font-size: 16px;
    }
    
  }
  @media screen and (max-width: 767px){
    .quick_footer1, .quick_footer2{
      display: block;
      margin-top: 10px;
      margin-bottom: 0px;
    }
    .quick_active_btn, .quick_inactive_btn{
      display: block;
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
    
    .quick_assessment_right{
      padding-right: 0vw;
      padding-left: 0px;
      height:auto;
      justify-content: center;
    }
    .quick_assessment_right input, .quick_assessment_right{
      text-align: center;
      display: block;
    }
  }